// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import colors from "../../assets/css/colors";
import { isAllTrue } from "../../helpers";
import type { ById, TPlayer, TRound } from "../../modules/types";
import clubColors from "../../utils/clubColors";
import CompareGraph from "./graph";

const DiagramWrapper = styled.div`
	width: 100%;
	height: 490px;
	background-color: #F0F3F7;
	/* box-shadow: 0 0 5px 0 rgba(127,138,144,0.5); */
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	position: relative;

	p {
		color: ${colors.coach};
		font-size: 10px;
	}

	> div:first-child {
		z-index: 1;
	}
`;

const BottomGradient = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 50%;
	z-index: 0;

	background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.05) 100%);
`;

type TPlayerScore = {
	round: number,
	value: number,
	is_projected: boolean
}

type TPlayerGraphData = {
	scores: TPlayerScore[],
	color: string,
	id: number,
	name: string,
};

const getPlayerScores = (
	player: TPlayer,
	rounds: TRound[],
	actual_round: TRound,
	statsBasis: string,
	has_assistant_coach: boolean,
): TPlayerScore[] => {
	const statString = statsBasis === "custom_stats" ? statsBasis : "stats";
	const raw_scores = _.get(player, [statString, "scores"], []);
	const raw_projected = _.get(player, [statString, "proj_scores"], []);
	const scores = [];

	const player_bye_round = _.find(rounds,
		round => _.includes(round.bye_squads, player.squad_id)
	) || { id: 0 };

	_.forEach(rounds, round => {
		if (round.id === player_bye_round.id) {
			return;
		}
		if (round.id < actual_round.id) {
			scores.push({
				round: round.id,
				value: _.get(raw_scores, round.id, 0),
				is_projected: false
			});
			return;
		}
		else if (round.id === actual_round.id) {
			if (_.has(raw_scores, round.id)) {
				scores.push({
					round: round.id,
					value: _.get(raw_scores, round.id, 0),
					is_projected: false
				});
				return;
			}
			else if (isAllTrue([_.has(raw_projected, round.id), has_assistant_coach])) {
				scores.push({
					round: round.id,
					value: _.get(raw_projected, round.id, 0),
					is_projected: true
				});
				return;
			}
			scores.push({
				round: round.id,
				value: 0,
				is_projected: false
			});
			return;
		}
		else if (isAllTrue([_.has(raw_projected, round.id), has_assistant_coach])) {
			scores.push({
				round: round.id,
				value: _.get(raw_projected, round.id, 0),
				is_projected: true
			});
			return;
		}
	});

	return scores;
};

const getPlayerData = (
	player_id: number,
	by_id: ById<TPlayer>,
	rounds: TRound[],
	actual_round: TRound,
	statsBasis: string,
	has_assistant_coach: boolean,
): TPlayerGraphData => {
	const player = by_id[player_id];
	const { first_name, last_name } = player;
	return {
		scores: getPlayerScores(player, rounds, actual_round, statsBasis, has_assistant_coach),
		color: clubColors[player.squad_id],
		id: player_id,
		name: `${first_name} ${last_name}`
	};
};

const getPlayersData = (
	players: number[],
	by_id: ById<TPlayer>,
	rounds: TRound[],
	actual_round: TRound,
	statsBasis: string,
	has_assistant_coach: boolean,
): TPlayerGraphData[] => {
	return _(players)
		.filter(player => _.has(by_id, player))
		.map(player => getPlayerData(player, by_id, rounds, actual_round, statsBasis, has_assistant_coach))
		.value();
};

type Props = {
	players: number[],
	players_by_id: ById<TPlayer>,
	rounds: TRound[],
	actual_round: TRound,
	statsBasis: string,
	has_assistant_coach: boolean,
};

export const CompareDiagram = ({ 
	players, players_by_id, rounds, actual_round, statsBasis, has_assistant_coach 
}: Props) => {
	const player_data = getPlayersData(players, players_by_id, rounds, actual_round, statsBasis, has_assistant_coach);
	return <DiagramWrapper>
		<CompareGraph data={player_data} />
		<BottomGradient />
	</DiagramWrapper>;
};

export default CompareDiagram;