// @flow
import * as React from "react";
import { get } from "lodash";
import {connect} from "react-redux";
import {Field} from "redux-form";
import {formValueSelector, reduxForm} from "redux-form";
import styled from "styled-components";
import moment from "moment/moment";
import {AccountValidate} from "../../utils/FormValidations";

import {
	getStates,
	//league_tooltips,
	registration_tooltips,
	getPlayerAvatar,
	getSkillLevel,
	getGenderOptions,
} from "../../helpers";
import {below} from "../../assets/css/media";

import {
	ButtonPrimary,
	FormField,
	FormSelect,
	FormUploadImage,
	DobSelect
} from "../../components";
import { REACT_APP_SSO_PROFILE_LINK } from "../../modules/constants";
import { DatePickerWrap, DOBErrorText } from "../login/afliDModalStyles";

const AccountFormStyled = styled.form`
	background: transparent;
	margin: 1em 0;
	box-sizing: border-box;
`;

const StyledLink = styled.a`
	border: 1px solid #1D4073;
	background-color: #1D4073;
	color: #ffffff;
	font-family: "SourceSansPro";
	font-size: 12px;
	font-weight: 600;
	line-height: 1;
	padding: 14px 12px 12px;
	cursor: pointer;
	width: auto;
	text-align: center;
	border-radius: 2px;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 20px;
	text-decoration: none;
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)`
	height: 40px;
	width: 140px;
	font-size: 14px;
`;

const TeamInfo = styled.div`
	display: flex;
	.avatar {
		margin-right: 1em;
	}

	.team_name {
		width: 100%;
	}
`;

const InputWrapper = styled.div`
	width: 440px;
	${below.phone`
		width: 100%;
	`};
`;

const WebRowMobCol = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	${below.phone`
		flex-direction: column;
	`};
`;

const DatePickerLabel = styled.label`
	display: flex;
	min-height: 20px;
	align-items: center;
    color: #1D4073;
    font-size: 12px;
	font-weight: 600;
	font-family: "Source Sans Pro";
    margin: 0;
    opacity: 1;
    margin-left: 0;
`;

// const Deactivate = styled.button`
// 	margin-left: 1em;
// 	color: #D92425;
// 	font-family: SourceSansPro;
// 	white-space: nowrap
// 	outline: none;
// 	border: none;
// 	border: 0;
// 	background-color: transparent;
// 	font-size: 14px;
// 	&:hover {
// 		text-decoration: underline;
// 		cursor: pointer;
// 	}
// 	${below.phone`
// 		margin-left: 0;
// 		margin-top: 1em;
// 	`};
// `;

const formatDateHandler = (date_data: string) => {
	const firstDOBArr = date_data.split("T")[0].split("-").reverse();
	const formatedDate = firstDOBArr.join("/");
	return formatedDate;
};

const formatDobMonth = (n: number) => (Number(n) < 10) ? ("0" + n) : String(n);

const formatDobDay = (n: number) => (Number(n) < 10) ? ("0" + n) : String(n);

type Props = {
	handleSubmit: Function;
	onChangeFile: Function;
	onChangeBirthday: Function;
	previewImg: string;
	squadsOptions: Array<Object>;
	// countriesOptions: Array<Object>;
	selected_country: string;
	user_data: Object;
	onDeactiveClick: Function;
};

const AccountFormComponent = ({
	handleSubmit,
	onChangeFile,
	onChangeBirthday,
	previewImg,
	squadsOptions,
	// countriesOptions,
	selected_country,
	user_data,
	onDeactiveClick,
}: Props) => {
	// use AFLIDButton styles for href
	const dateTime = new Date(user_data.birthday);

	const [dob_year, setDobYear] = React.useState(dateTime.getFullYear());
	const [dob_month, setDobMonth] = React.useState(dateTime.getMonth() + 1);
	const [dob_day, setDobDay] = React.useState(dateTime.getDate());
	const [dobErrorText, setDobErrorText] = React.useState("");


	const handleFormUpdate = event => {
		setDobErrorText("");
		if (event.target.name === "dob_year") {
			setDobYear(event.target.value);
		}
		if (event.target.name === "dob_month") {
			setDobMonth(event.target.value);
		}
		if (event.target.name === "dob_day") {
			setDobDay(event.target.value);
		}
	};

	React.useEffect(()=>{
		const yearGTNow = Number(dob_year) >= moment().year();
		const dobyyyymmdd = `${formatDobDay(dob_day)}-${formatDobMonth(dob_month)}-${String(dob_year)}`;
		const momentDate = moment(dobyyyymmdd, 'DD-MM-YYYY', true);

		const isValidDate = momentDate.isValid();

		if(!isValidDate || yearGTNow) {
			setDobErrorText(
				"please input a valid birthday"
			);
		} 
		else {
			dateTime.setFullYear(dob_year);
			dateTime.setMonth(dob_month - 1);
			dateTime.setDate(dob_day);
			dateTime.setHours(12, 0, 0, 0);
			onChangeBirthday(dateTime.toISOString());
		}
		// eslint-disable-next-line
	},[dob_day, dob_month, dob_year]);

	return (
		<AccountFormStyled onSubmit={handleSubmit}>
			<InputWrapper>
				<TeamInfo>
					<FormUploadImage
						name="avatar"
						size="75px"
						className="avatar"
						onChange={onChangeFile}
						preview_img={previewImg ? previewImg : getPlayerAvatar(user_data)}
						add_text=""
					/>
					<Field
						label="Team name*"
						type="text"
						name="team_name"
						placeholder="Team name"
						className="team_name"
						component={FormField}
						tooltip={registration_tooltips.team_name}
					/>
				</TeamInfo>
				{/* <Field
					label="Country of residence*"
					name="country"
					component={FormSelect}
					options={countriesOptions}
				/> */}
				{selected_country === "AU" ? (
					<Field label="State*" name="state" component={FormSelect} options={getStates} />
				) : null}
				<Field
					label='Gender*'
					name='gender'
					component={FormSelect}
					options={getGenderOptions}
					tooltip={registration_tooltips.gender}
				/>
				<Field 
					label="Skill Level*"
					name="skill_level"
					required={true}
					className="skill_level"
					component={FormSelect}
					options={getSkillLevel}
				/>
				

				{/* <Field
					label="Date of birth*"
					name="birthday"
					placeholder="Date of birth"
					component={FormDateField}
					isValidDate={getValidDateGender}
					maxDate={new Date()}
					readOnly={false}
					timeFormat={false}
					dateFormat="DD/MM/YYYY"
					tooltip={registration_tooltips.birthday}
				/> */}

				<DatePickerWrap>
					<DatePickerLabel>Date of birth*</DatePickerLabel>
					<DobSelect
						handleChange={handleFormUpdate} 
						dob_day={String(dob_day)}
						dob_month={String(dob_month)}
						dob_year={String(dob_year)}
					/>
					<DOBErrorText>{dobErrorText}</DOBErrorText>
				</DatePickerWrap>

				<WebRowMobCol>
					<ButtonPrimaryStyled>Update</ButtonPrimaryStyled>
					{/* <Deactivate 
						onClick={onDeactiveClick}>Deactivate your account</Deactivate> */}

				</WebRowMobCol>
				<WebRowMobCol>
					<StyledLink href={REACT_APP_SSO_PROFILE_LINK} target="_blank">
						Manage My AFL iD
					</StyledLink>
				</WebRowMobCol>
			</InputWrapper>
		</AccountFormStyled>
	);
};

let AccountForm = reduxForm({
	form: "account_form",
	validate: AccountValidate,
})(AccountFormComponent);

const selector = formValueSelector("account_form");

const mapStateToProps = state => {
	const selected_country = selector(state, "country");
	const user_data = state.user.data;
	const birthday = get(user_data, 'birthday', "");
	const initial_dob = formatDateHandler(birthday);

	return {
		selected_country,
		user_data,
		initialValues: {
			avatar: "",
			team_name: user_data.team_name,
			country: user_data.country,
			state: user_data.state,
			gender: user_data.gender,
			birthday: initial_dob,
			skill_level: user_data.skill_level
		},
	};
};

AccountForm = connect(mapStateToProps, null)(AccountForm);

export default AccountForm;