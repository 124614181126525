// @flow
import _, { get } from "lodash";
import moment from "moment";


import pkceChallenge from "pkce-challenge";
import {v4 as uuidv4} from "uuid";
import type { TRoundWithMatches } from "../modules/types";
import type { TPLeague } from "../modules/types";
import {
	REACT_APP_SSO_URL,
	REACT_APP_SSO_CLIENT_ID,
	REACT_APP_SSO_REDIRECT_URI,
	REACT_APP_PUBLIC_URL
} from "../modules/constants";

const {code_verifier, code_challenge} = pkceChallenge(128);

export const getLoginLink = () => {
	const nonce: string = uuidv4();
	const myState: string = uuidv4();
	localStorage.setItem("code_verifier", code_verifier);
	localStorage.setItem("code_challenge", code_challenge);
	localStorage.setItem("nonce", nonce);
	localStorage.setItem("myState", myState);
	const AUTHORISE_API = `${REACT_APP_SSO_URL}/authorize?`;
	const params = [
		"scope=openid email profile",
		"response_type=code",
		`redirect_uri=${REACT_APP_SSO_REDIRECT_URI}`,
		`state=${myState}`,
		`nonce=${nonce}`,
		`client_id=${REACT_APP_SSO_CLIENT_ID}`,
		`code_challenge=${code_challenge}`,
		"code_challenge_method=S256",
		// 'response_type=code',
		// 'scope=openid profile email okta.myAccount.profile.read'
	];
	return `${AUTHORISE_API}${params.join("&")}`;
};

export const getLogoutParams = (id_token: string) => {
	const params = [
		`id_token_hint=${id_token}`,
		`post_logout_redirect_uri=${REACT_APP_PUBLIC_URL}`,
		`client_id=${REACT_APP_SSO_CLIENT_ID}`,
	];
	return `?${params.join("&")}`;

};

export const LEAGUE_SIZE = _.range(4, 21, 2).map(value => ({ value, text: value }));

type Props = {
	league_size: number,
};

export type ScoreSettingsProps = {
	title: string,
	description: string,
	stat: string,
	defaultValue: number,
	order: number,
	isPremium: boolean,
	key: string,
	minValue?: number,
	maxValue?: number,
};

export const isAllTrue = (array: boolean[]) => {
	let allTrue = true;
	array.forEach(value => {
		if(!Boolean(value)){
			allTrue = false;
		}
	});
	return allTrue;
};

export const isAnyTrue = (array: boolean[]) => {
	let anyTrue = false;
	array.forEach(value => {
		if(Boolean(value)){
			anyTrue = true;
		}
	});
	return anyTrue;
};

export const getLeagueFinalFormat = ({ league_size }: Props) => {
	let max_users = league_size > 8 ? 8 : league_size;

	return _.rangeRight(2, ++max_users, 1)
		.filter(num => (num % 2 === 0 || num === 5))
		.map(value => ({ value, text: `Top ${value}` }));
};

export const getClassicLeagueFinalFormat = ({ league_size }: Props) => {
	let max_users = league_size > 8 ? 8 : league_size;

	return _.rangeRight(2, ++max_users, 1)
		.filter(num => (num % 2 === 0 ))
		.map(value => ({ value, text: `Top ${value}` }));
};

export const getPartialByeRound = (playerSquadId: number, rounds: any[]) => {
	const partialBye = rounds.find(round => round.is_partial_bye 
		&& round.bye_squads.includes(playerSquadId));
	return _.get(partialBye, 'id');
};

export const BYE_ROUNDS = [12, 13, 14, 15, 16];
export const PARTIAL_BYE_ROUNDS = [2,3,4];

export const SQUAD_SIZE = [
	{
		value: "2-3-1-2/4",
		text: "2 Defs - 3 Mids - 1 Ruck - 2 Fwds / 4 Emerg",
	},
	{
		value: "2-3-1-2/8",
		text: "2 Defs - 3 Mids - 1 Ruck - 2 Fwds / 8 Emerg",
	},
	{
		value: "3-4-1-3/4",
		text: "3 Defs - 4 Mids - 1 Ruck - 3 Fwds / 4 Emerg",
	},
	{
		value: "3-4-1-3/8",
		text: "3 Defs - 4 Mids - 1 Ruck - 3 Fwds / 8 Emerg",
	},
	{
		value: "5-7-1-5/4",
		text: "5 Defs - 7 Mids - 1 Ruck - 5 Fwds / 4 Emerg",
	},
	{
		value: "5-7-1-5/8",
		text: "5 Defs - 7 Mids - 1 Ruck - 5 Fwds / 8 Emerg",
	},
	{
		value: "6-8-2-6/4",
		text: "6 Defs - 8 Mids - 2 Ruck - 6 Fwds / 4 Emerg",
	},
	{
		value: "6-8-2-6/8",
		text: "6 Defs - 8 Mids - 2 Ruck - 6 Fwds / 8 Emerg",
	},
];

export const getStartDraftDate = () => {
	const start_date = moment.unix(process.env.REACT_APP_START_DRAFT_DATE),
		now_date = new Date(),
		future_start_date = moment(now_date.setMinutes(now_date.getMinutes() + 30));

	return start_date.unix() >= future_start_date.unix() ? start_date : future_start_date;
};

export const squadSizeSettings = {
	DEF: {
		title: "Defenders",
		description: "The amount of Defenders users will have within their league.",
		stat: "DEF",
		defaultValue: 2,
		order: 1,
		isPremium: false,
		minValue: 1,
		maxValue: 10,
	},
	MID: {
		title: "Midfielders",
		description: "The amount of Midfielders users will have within their league.",
		stat: "MID",
		defaultValue: 3,
		order: 2,
		isPremium: false,
		minValue: 1,
		maxValue: 10,
	},
	RUC: {
		title: "Rucks",
		description: "The amount of Rucks users will have within their league.",
		stat: "RUC",
		defaultValue: 1,
		order: 3,
		isPremium: false,
		minValue: 1,
		maxValue: 10,
	},
	FWD: {
		title: "Forwards",
		description: "The amount of Forwards users will have within their league.",
		stat: "FWD",
		defaultValue: 2,
		order: 4,
		isPremium: false,
		minValue: 1,
		maxValue: 10,
	},
	INT: {
		title: "Interchange Size",
		description: "The amount of Bench players users will have within their league.",
		stat: "INT",
		defaultValue: 2,
		order: 5,
		isPremium: false,
		minValue: 1,
		maxValue: 41, // plugin takes 1 from total so its actually 40
	},
};

const squadSizeKeys: string[] = Object.keys(squadSizeSettings);
export const orderedSquadSizeKeys: ScoreSettingsProps[] = squadSizeKeys
	.map(item => ({
		...squadSizeSettings[item],
		key: item,
	}))
	.sort((a, b) => a.order - b.order);

const isPremiumStat = true; // will be true in 2021

export const score_settings = {
	K: {
		title: "Kicks",
		description: "Disposing of the ball by foot.",
		stat: "K",
		defaultValue: 3,
		order: 1,
		isPremium: false,
	},
	H: {
		title: "Handballs",
		description: "Disposing of the ball by hand.",
		stat: "H",
		defaultValue: 2,
		order: 2,
		isPremium: false,
	},
	M: {
		title: "Marks",
		description: "When a player cleanly catches , " +
			"(is deemed to have controlled the ball for sufficient time) " +
			"a kicked ball that has travelled more than 15 metres without anyone " +
			"else touching it or the ball hitting the ground.",
		stat: "M",
		defaultValue: 3,
		order: 3,
		isPremium: false,
	},
	T: {
		title: "Tackles",
		description: "Using physical contact to prevent an opponent in possession of the ball " +
		"from getting an effective disposal.",
		stat: "T",
		defaultValue: 4,
		order: 4,
		isPremium: false,
	},
	FF: {
		title: "Free Kicks For",
		description: "When a player is interfered with and is awarded a free kick by the umpires.",
		stat: "FF",
		defaultValue: 1,
		order: 5,
		isPremium: false,
	},
	FA: {
		title: "Free Kicks Against",
		description: "When an infringement occurs resulting in the opposition " + 
		"receiving a free kick " + 
		"from the umpires.",
		stat: "FA",
		defaultValue: -3,
		order: 6,
		isPremium: false,
		minValue: -6,
	},
	G: {
		title: "Goals",
		description: " A major score, as judged by the goal umpire. " + 
		"Worth six points to a team's total score.",
		stat: "G",
		defaultValue: 6,
		order: 7,
		isPremium: false,
	},
	B: {
		title: "Behinds",
		description: "A minor score, as judged by the goal umpire. "+
		"Behinds are worth one point to a team's total score.",
		stat: "BH",
		defaultValue: 1,
		order: 8,
		isPremium: false,
	},
	HO: {
		title: "Hitouts",
		description: "Knocking the ball out of a ruck contest following a stoppage " +
		"with clear control, regardless of which side wins the following contest at ground level.",
		stat: "HI",
		defaultValue: 1,
		order: 9,
		isPremium: false,
	},
	GA: {
		title: "Goal Assists",
		description: "Creating a scoring shot for a teammate that results in a goal.",
		stat: "GA",
		defaultValue: 0,
		order: 10,
		isPremium: isPremiumStat,
	},
	D: {
		title: "Disposals",
		description: "Legally moving the ball, via a handball or kick.",
		stat: "D",
		defaultValue: 0,
		order: 11,
		isPremium: isPremiumStat,
	},
	ED: {
		title: "Effective Disposals",
		description:
			"Disposals that are sent to a teammate's advantage, " +
			"or gain 40 metres or more without disadvantaging the player's team.",
		stat: "ED",
		defaultValue: 0,
		order: 12,
		isPremium: isPremiumStat,
	},
	IED: {
		title: "Ineffective Disposals",
		description:
			"Disposals that are not to the team's advantage, " +
			"but are not turned over directly to the opposition.",
		stat: "IED",
		defaultValue: 0,
		order: 13,
		isPremium: isPremiumStat,
	},
	R50: {
		title: "Rebound 50s",
		description: "Moving the ball from the defensive zone into the midfield.",
		stat: "R50",
		defaultValue: 0,
		order: 14,
		isPremium: isPremiumStat,
	},
	I50: {
		title: "Inside 50s",
		description:
			"Moving the ball from the midfield into the forward zone. " +
			"Excludes multiple entries within the same chain of possession",
		stat: "I50",
		defaultValue: 0,
		order: 15,
		isPremium: isPremiumStat,
	},
	CL: {
		title: "Clearances",
		description:
			"Credited to the player who has the first effective disposal in " +
			"a chain that clears the stoppage area, or an ineffective kick or " +
			"clanger kick that clears the stoppage area.",
		stat: "CL",
		defaultValue: 0,
		order: 16,
		isPremium: isPremiumStat,
	},
	SP: {
		title: "Spoils",
		description:
			"Knocking the ball away from a marking contest preventing an opponent " +
			"from taking a mark.",
		stat: "SP",
		defaultValue: 0,
		order: 17,
		isPremium: isPremiumStat,
	},
	CP: {
		title: "Contested Possessions",
		description:
			"A possession which has been won when the ball is in dispute. " +
			"Includes looseball-gets, hardball-gets, contested marks, " +
			"gathers from a hitout and free kicks won with the ball in dispute.",
		stat: "CP",
		defaultValue: 0,
		order: 18,
		isPremium: isPremiumStat,
	},
	UCP: {
		title: "Uncontested Possessions",
		description:
			"Possessions gained whilst under no physical pressure, " +
			"either from a teammate's disposal or an opposition's clanger kick. " +
			"Includes handball receives, uncontested marks (including lead marks) " +
			"and intended ball gets from a disposal.",
		stat: "UCP",
		defaultValue: 0,
		order: 19,
		isPremium: isPremiumStat,
	},
	CM: {
		title: "Contested Marks",
		description:
			"When a player takes a mark under physical pressure of an opponent or in a pack.",
		stat: "CM",
		defaultValue: 0,
		order: 20,
		isPremium: isPremiumStat,
	},
	FD: {
		title: "Free Kick Differential",
		description: "Difference between free kicks awarded and free kicks given away.",
		stat: "FD",
		defaultValue: 0,
		order: 21,
		isPremium: isPremiumStat,
	},
	CS: {
		title: "Clangers",
		description:
			"An error made by a player resulting in a negative result for his side. " +
			"Disposal clangers are any kick or handball that directly turns the ball " +
			"over to the opposition. Frees & 50 metre penalties against, No Pressure " +
			"Errors, Dropped Marks, ball up Kick-Ins and Debits are all included in clangers.",
		stat: "CS",
		defaultValue: 0,
		order: 22,
		isPremium: isPremiumStat,
	},
};

const scoreSettingKeys: string[] = Object.keys(score_settings);
export const orderedScoreSettingValues: ScoreSettingsProps[] = scoreSettingKeys
	.map(item => ({
		...score_settings[item],
		key: item,
	}))
	.sort((a, b) => a.order - b.order);

type FormValues = {|[key: string]: number|};

export const defaultScoring: FormValues | $Shape<{||}> = scoreSettingKeys.reduce(
	(obj, item) =>
		({
			...obj,
			[item]: score_settings[item].defaultValue,
		}: $FlowFixMe),
	{}
);

export const league_tooltips = {
	name: {
		title: "LEAGUE NAME",
		description:
			"Max of 40 characters. " +
			"Inappropriate league names will be " +
			"deleted and replaced with your team name.",
	},
	size: {
		title: "LEAGUE SIZE",
		description:
			"Your league size needs to have " +
			"an even number of teams from 4 - 20 and work in " +
			"conjunction with other league settings to be valid",
	},
	start_round: {
		title: "LEAGUE START ROUND",
		description:
			"This can be changed " +
			"to select any round as your preferred " +
			"start round provided it is a valid round " +
			"based on other configurable league settings",
	},
	privacy: {
		title: "PRIVACY SETTINGS",
		description:
			"Private leagues can only be joined " +
			"via invite or league code. Public leagues are available " +
			"for anyone to join.",
	},
	draft_start: {
		title: "Draft Date",
		description:
			"Times are in local time. " +
			"Choose what date and time the Draft will commence. " +
			"Note that the draft cannot begin with in 30 minutes " +
			"of the league being created. If selecting a " +
			"Commissioner draft, your draft is available after " +
			"creating the league and has no timers.",
	},
	draft_type: {
		title: "DRAFT TYPE",
		description:
			"Live drafts involve players " +
			"utilising real-time strategy and reactionary " +
			"measures to acquire specific available players. Auto-drafts " +
			"use preliminary draft rankings set by each User " +
			"to automate the " +
			"draft cycle and establish the teams.Commissioner " +
			"drafts require the League " +
			"Commissioner to manually add the selection by each " +
			"User using " +
			"the Commissioner view.",
	},
	draft_order: {
		title: "DRAFT ORDER",
		description:
			"You can choose either the Snake or Linear " +
			"Draft Order. The Snake Draft Order means that the player " +
			"who has the 1st pick in the odd Rounds (1,3,5 etc..) of " +
			"the draft will have the last pick in the even " +
			"rounds (2,4,6 etc...). " +
			"The Linear Draft order means that the order that is " +
			"established for " +
			"the first round is then carried on through the " +
			"following rounds. " +
			"That means if you have selection 1 in the " +
			"1st round you will " +
			"also have selection one in the following rounds.",
	},
	draft_turn: {
		title: "Draft Turn Time",
		description:
			"As the commissioner of the league you " +
			"are able to choose how long each Team has to make their " +
			"pick during the draft. If a team takes longer than the " +
			"allotted time their pick will be automatically made for them " +
			"based on their pre-draft list.",
	},
	finals: {
		title: "LEAGUE FINAL SERIES",
		description:
			"You have the option for the top " +
			"teams to play against each other in a knockout " +
			"series at the end of your season. If you select 'No', " +
			"the winner of the league is the highest ranked team at " +
			"the end of the final round of your season.",
	},
	finals_format: {
		title: "FINALS FORMAT",
		description:
			"Select how many teams play off " +
			"in your leagues Primary Finals. 8 teams runs over " +
			"4 weeks, 6 teams over 3 weeks, 4 teams over 2 week and " +
			"2 teams is just a single round grand final match up. " +
			"If there are enough teams in your league to run Secondary " +
			"Finals of the same structure this will happen automatically as well.",
	},
	bye_rounds: {
		title: "BYE ROUNDS",
		description:
			"This can be changed to 'On' " +
			"to include the bye rounds in your league's head " +
			"to head fixtures. If set to 'On' you will play " +
			"against another team during these Bye Rounds. " +
			"If set to 'Off', your league will not have head " +
			"to head fixtures for these rounds.",
	},
	coaches_box: {
		title: "COACHES BOX",
		description: "Turn on/off Coaches Box chat for the league",
	},
	ladder_tiebreaker: {
		title: "LADDER TIEBREAKER",
		description:
			"Select the tiebreaker for your league ladder. " +
			"Percentage (Points for divided by points against), or Points for.",
	},
	play_times: {
		title: "PLAY OPPONENTS",
		description:
			"This can be changed so " +
			"that teams can play each other more than " +
			"once to work in conjunction with other " +
			"configurable league settings like league size.",
	},
	squad_size: {
		title: "Squad Size",
		description:
			"The selected options indicate the Defenders - " +
			"Midfielders - Rucks - Forwards / Emergencies " +
			"users will have within their league. Based on the number options, " +
			"this is how many players in the respective  " +
			"positions users will be required to select. ",
	},
	captains: {
		title: "Captains",
		description:
			"If Captains are enabled then a " +
			"nominated player in each squad will receive double " +
			"points for each round. Captains can be changed on " +
			"a week to week basis. If there are no Captains then " +
			"all players will receive equal weighted points.",
	},
	emergency: {
		title: "Emergencies",
		description:
			"Whether there are emergencies is " +
			"up to the commissioner. If emergencies are turned " +
			"on then the emergencies will cover a player who " +
			"DNP with a matching position. The Emergency will " +
			"be the lowest scoring player from that position on the bench.",
	},
	original_positions:{
		title: "Enable auto updating of Dual Position Players (DPP)",
		description:
			"During the season players positions are reviewed.  " +
			"If a player has been playing primarily in a position that  " +
			"they are not originally eligible for, a player positional  " +
			"change may be made.  " +
			"You are able to select whether player positions can update  " +
			"during the season if the site administrators determine new " +
			"positions should be added, or whether positions are ser and the  " +
			"start of the season should be maintained throughout. " +
			"Note that position eligibility is only added and never removed " +
			"when administrators make changes.",
	},
	lockout: {
		title: "Rolling Lockout",
		description:
			"Select what time coaches can no longer make changes "+
			"to their lineups (i.e. move players between on field and bench positions)." +
			"Rolling Lockout: Only players whose matches have started will lock out. "+
			"Players who aren’t locked can continue to be substituted. "+
			"Standard Lockout: All players lock out at the start of the "+
			"first game of the round. This applies even if the first game "+
			"of the round occurs early on a Wednesday or Thursday. "+
			"Saturday Lockout: Rolling lockout up until the start of the "+
			"first match on Saturday, at which point all players lock out"
	},
	trade_waivers: {
		title: "Restricted Free Agents",
		description:
			"If Restricted Free Agent system " +
			"is turned on, when a Player is delisted, " +
			"he will be classified as a Restricted Free " +
			"Agent until the amount of time designated by " +
			"the Commissioner has passed. After this time " +
			"he will become a Free Agent. Any User can pick " +
			"up any Free Agent at anytime. Any User can submit " +
			"a request to pick up a Restricted Free Agent, " +
			"however the User with the higher RFA Priority will " +
			"get the Player if multiple Users select that Player",
	},
	waiver_period: {
		title: "Restricted Free Agent Time Period",
		description:
			"The Restricted Free Agent Time Period " +
			"refers to the period of time that coaches have to " +
			"submit their Restricted Free Agent Bid.",
	},
	waiver_order: {
		title: "Restricted Free Agent Time Policy",
		description:
			"If you decide to have Restricted " +
			"Free Agents turned 'ON' there are two order policies. " +
			"The first being that the order is decided by reversing " +
			"the ladder i.e. The Team that is positioned last on the " +
			"ladder gets the first selection. The second waiver order " +
			"policy is that if you had the 1st pick last week you will " +
			"now have the last pick in the upcoming week. The week after " +
			"that you will have the second last pick and so on.",
	},
	rfa_privacy: {
		title: "Restricted Free Agent Privacy",
		description:
			"Private RFA selections ensure " +
			"that other coaches are unable to see your " +
			"pending RFA requests and will only be able to " +
			"see your fellow coaches successful RFA requests " +
			"once they have been processed.",
	},
	approve_trade: {
		title: "Trade Approval Options",
		description: "Manage the approval settings for Trades made between teams in your league.",
	},
	type: {
		title: "League Type",
		description:
			"Head-to-head leagues require an even number " +
			"of teams and each week you will play against a different " +
			"team in your league. " +
			"Open/Standard leagues can have any number of " +
			"teams and rankings are based on total Fantasy points.",
	},
	keeper: {
		title: "Keeper Leagues",
		description: "Enable your league as a keeper league. "+ 
		"This means that if you regenerate your league next year, "+
		"teams will be able to select a certain amount of "+
		"players that they want to keep from last year's roster."
	},
	keeper_num: {
		title: "Number of Keepers",
		description: 
			"Number of players your league intends to keep from their previous year’s roster.",
	},
	keeper_deadline: {
		title: "Keeper Selection Deadline",
		description: "This is the deadline for when people in "+
		"your league have to nominate their keepers. After this "+
		"deadline, keepers will be locked in. Your Keeper Selection "+
		"Deadline must be at least 1 hour before your draft's start "+
		"time. The commissioner can unlock keepers by setting this "+
		"date to a point in the future.",
	},
	num_emergencies: {
		title: "Number of Emergencies",
		description: 
			"This option determines the amount of emergencies each team can have. " +
			"Note: there is only a maximum of 1 emergency per position."
	},
	tog_threshold_emergencies: {
		title: "Emergency TOG Threshold",
		description: 
			"Players who play less than the selected percentage of game time will " +
			"be replaced in the line-up by a playing emergency. This can be useful " +
			"to avoid cases where a starter has a poor performance due to " +
			"getting injured early in a match is sub-affected."
	},
	finals_consolation: {
		title: "Play a consolation finals series",
		description: 
			"Determines whether the teams that do not qualify " +
			"for the finals play a consolation finals series concurrently " +
			" with the league finals series."
	},
	finals_start_round: {
		title: "Finals start round",
		description: 
			"Choose which round your league finals begin in."
	},
	finals_length: {
		title: "Finals length",
		description: 
			"Choose how many weeks your league finals will be played over."
	},
	consolation_finals_length: {
		title: "Consolation finals length",
		description: 
			"Choose how many weeks your league consolation finals will be played over."
	},
	general_scoring: {
		title: "Scoring Values",
		description: 
			"The points awarded for specific statistics a player can receive."
	}
};

export const registration_tooltips = {
	email: {
		title: "Email",
		description: "Your email will be required to login.",
	},
	password: {
		title: "Password",
		description: "Please use a secure password and store in a safe place",
	},
	team_name: {
		title: "Team name",
		description:
			"Your team name will be displayed for" +
			" other users to see in your leagues and in the rankings. " +
			"Maximum of 40 characters.",
	},
	skill_level: {
		title: "Skill level",
		description:
			"This doesn't change the way " +
			"AFL Fantasy is played, but can help you find leagues of a similar skill level.",
	},
	mobile: {
		title: "Phone number",
		description: "Please enter a valid phone number, ensuring only numbers are used.",
	},
	birthday: {
		title: "Date of birth",
		description:
			"Date of Birth is required. Please enter a valid date of birth using DD/MM/YYYY",
	},
	gender: {
		title: "Gender",
		description: "Gender is required",
	},
	privacy_policy: {
		title: "Privacy Policy",
		description: "Agreeing to Terms & Conditions is required",
	},
	toyota: {
		title: "Toyota Sponsor",
		description: "Your choice is required",
	}
};

export const lockoutContent = (league: TPLeague) => {
	switch (league.lockout) {
		case 0:
			return "Standard Lockout";
		case 1:
			return "Rolling Lockout";
		case 2:
			return "Saturday Lockout";
		default:
			return "";
	}
};

export const lockoutKey = (league: TPLeague) => {
	switch (league.lockout) {
		case 0:
			return "standard";
		case 1:
			return "rolling";
		case 2:
			return "saturday";
		default:
			return "";
	}
};

export const getIsLockedFromLockout = (
	playerLocked: boolean, 
	is_static: boolean, 
	selected_round:TRoundWithMatches,
	edit_teams_view: boolean = false
) => {
	const playerLockedOrStatic = playerLocked || is_static;
	const selectedRoundLockedOut = _.get(selected_round, "lockout") === "full";
	const selectedRoundSatLockedOut = _.get(selected_round, "saturday_lockout") === "full";
	return edit_teams_view ? false: playerLockedOrStatic ||  
		(selectedRoundSatLockedOut || selectedRoundLockedOut);
};

const getSuffix = (j: number, k: number) => {
	let suffix = "th";

	if (j === 1 && k !== 11) {
		suffix = "st";
	}

	if (j === 2 && k !== 12) {
		suffix = "nd";
	}

	if (j === 3 && k !== 13) {
		suffix = "rd";
	}

	return suffix;
};

export const ordinalSuffixOf = (num: number) => {
	if (!parseInt(num, 10)) {
		return num;
	}
	const j = num % 10,
		k = num % 100;

	let suffix = getSuffix(j, k);

	return num + suffix;
};

export const getStates = [
	{
		value: "",
		text: "Your state",
	},
	{
		value: "ACT",
		text: "Australian Capital Territory",
	},
	{
		value: "NT",
		text: "Northern Territory",
	},
	{
		value: "NSW",
		text: "New South Wales",
	},
	{
		value: "VIC",
		text: "Victoria",
	},
	{
		value: "SA",
		text: "South Australia",
	},
	{
		value: "WA",
		text: "Western Australia",
	},
	{
		value: "TAS",
		text: "Tasmania",
	},
	{
		value: "QLD",
		text: "Queensland",
	},
];

export const getSkillLevel = [
	{
		value: "",
		text: "Skill Level",
	},
	{
		value: "beginner",
		text: "Rookie",
	},
	{
		value: "intermediate",
		text: "Intermediate",
	},
	{
		value: "expert",
		text: "Seasoned",
	},
];

export const getGenderOptions = [
	{
		value: "",
		text: "Please select an option for gender",
		disabled: true
	},
	{
		value: "1",
		text: "Male"
	},
	{
		value: "0",
		text: "Female",
	},
	{
		value: "3",
		text: "Non-Binary"
	},
	{
		value: "4",
		text: "Other"
	},
	{
		value: "2",
		text: "Prefer not to say"
	},
];

export * from "./teamsClassic";

type UserData = {
	id: number,
	avatar_version: number,
};

export const getPlayerAvatar = ({ id, avatar_version = 0 }: UserData) => {
	const base_path = process.env.REACT_APP_AVATARS_PATH || "";
	return `${base_path}${id}.png?v=${avatar_version}`;
};

export const getIsRegenerated = (afRegenerated:number, ufRegenerated:number ) =>
	Boolean((afRegenerated && afRegenerated !== 0) || (ufRegenerated && ufRegenerated !== 0));

export const immutableMove = (arr: number[], from: number, to: number)  => {
	return arr.reduce((prev, current, idx, self) => {
	  if (from === to) {
			prev.push(current);
	  }
	  if (idx === from) {
			return prev;
	  }
	  if (from < to) {
			prev.push(current);
	  }
	  if (idx === to) {
			prev.push(self[from]);
	  }
	  if (from > to) {
			prev.push(current);
	  }
	  return prev;
	}, []);
};

const arrayToObject = (
	array: Array<Object>,
	propName: string,
	initialObject: Object = {},
): Object => {
	if (Array.isArray(array) === false) {
		throw new
		Error('arrayToObject requires a valid array to be passed in as the first parameter');
	}
	if ((propName && typeof propName === 'string') === false) {
		throw new
		Error('arrayToObject requires a propName param of type str to be passed in as 2nd param');
	}
	return array.reduce((obj, item) => {
		obj[item[propName]] = item;
		return obj;
	}, { ...initialObject });
};

export const arrayToObjectById = (
	array: Array<Object>,
	initialObject?: Object,
): Object => arrayToObject(array, 'id', initialObject);

export const IS_IE =
	window.navigator.userAgent.indexOf("MSIE ") > 0 ||
	(!!window.MSInputMethodContext && !!document.documentMode);


export const getAllowedEmailsPromise = async () => {
	const jsonFile = await fetch("https://fantasy.afl.com.au/data/afl/allowedEmails.json");
	const data = await jsonFile.json();
	
	return get(data, 'allowedEmails', []);
};

export const isRound7Started = (actual_round: Object) => {
	// TODO ADD BACK IN NEXT RELEASE
	const actualRoundId = _.get(actual_round, 'id');
	if(actualRoundId > 7){
		return true;
	}
	const actualRoundStatus = _.get(actual_round, 'status');
	const isRound7Started = actualRoundId === 7 && actualRoundStatus !== "scheduled";
	return isRound7Started;
};

export const getAllowedEmails = getAllowedEmailsPromise().then(data => data);
export * from "./league";

export * from "./complex";