
// @flow
import _ from "lodash";
import type { TRound, TRoundWithMatches } from "../../modules/types";
import type { TPlayersById, TCoachPlayerStatsById } from "../../modules/types";
import { findMatch } from "../rounds";
import { getTeamScore } from "../teamsClassic";
import type {TTeam} from "../../modules/types";
import { getLeaderId } from "../complex/team";
import { isAllTrue, isAnyTrue } from "..";

type TLineup = Object;
type TLeague = Object;



export const getPlayerIDsFromLineup = (lineup: TLineup) => {
	return Object.keys(lineup).map(position => {
		return lineup[position];
	}).flat();
};

export const getLineupIdsPositions = (lineup: TLineup) => {
	const lineReducer = item => _.reduce(item, (result, value) => {
		return {
			...result,
			...value
		};
	}, {});

	return _.chain(lineup)
		.reduce((result, value, key) => {
			const ids = _.map(value, id => {
				return { [id]: parseInt(key, 10) };
			});
			return {
				...result,
				...lineReducer(ids)
			};
		}, {})
		.value();
};

const getProjLeaderId = (
	lineup: TLineup,
	players_by_id: TPlayersById,
	round: TRoundWithMatches
) => {
	const { captain, vice_captain } = lineup;
	let proj_leader_id = captain || vice_captain;

	if (proj_leader_id) {
		const match_finished = matchFinished(round, proj_leader_id, players_by_id);
		let leader_score;
		const round_id = _.get(round, "id", 0);

		// If their match hasn't finished, only sub in the VC if the projected is zero
		if (!match_finished) {
			leader_score = getProjectedScore(proj_leader_id, players_by_id, round_id);
			if (leader_score === 0) {
				// If the leader is projected at zero, use vice-captain
				return vice_captain;
			}
		}
		else {
			// If their match has finished, use their real score
			leader_score = getRealScore(proj_leader_id, players_by_id, round_id, null);
		}

		if (_.isNil(leader_score)) {
			// If the score was null/undefined, the leader DNP, so sub in the VC
			return vice_captain;
		}
	}

	return proj_leader_id;
};

const matchFinished = (round: TRoundWithMatches, player_id: number, players_by_id: Object) => {
	const squad_id = _.get(players_by_id, [player_id, "squad_id"], 0);

	const match = findMatch(_.get(round, "matches", []), squad_id);

	const status = _.get(match, "status");

	return status === "complete";
};

const getRealScore = (
	player_id: number,
	players_by_id: TPlayersById,
	round_id: number,
	default_value: any = 0,
	custom_scoring_enabled?: boolean = false
) => {
	return _.get(
		players_by_id,
		[player_id, custom_scoring_enabled ? "custom_stats" : "stats", "scores", round_id],
		default_value
	);
};

const getProjectedScore = (
	player_id: number,
	players_by_id: TPlayersById,
	round_id: number,
	default_value: any = 0,
	custom_scoring_enabled?: boolean = false
) => {
	return _.get(
		players_by_id,
		[player_id, custom_scoring_enabled ? "custom_stats" : "stats", "proj_scores", round_id],
		default_value
	);
};

const getRealOrProjectedScore = (
	player_id: number,
	players_by_id: TPlayersById,
	round: TRoundWithMatches,
	custom_scoring_enabled?: boolean = false
) => {
	const round_id = _.get(round, "id", 0);
	const match_finished = matchFinished(round, player_id,  players_by_id);

	// If the player's match hasn't finished, give projected score
	if (!match_finished) {
		return getProjectedScore(player_id, players_by_id, round_id, 0, custom_scoring_enabled);
	}

	return getRealScore(player_id, players_by_id, round_id, null, custom_scoring_enabled);
};

const getEmergencyScore = (
	emergency: Object,
	position_id: number,
	round: TRoundWithMatches,
	players_by_id: TPlayersById,
	is_classic: boolean,
	custom_scoring_enabled?: boolean = false
) => {
	let score;
	if (is_classic) {
		const position_bench = _.get(emergency, position_id, []);
		const emgs = _.get(emergency, "emergency", []);

		const emergency_player = _(emgs)
			// Remove anyone not in correct position
			.filter(p_id => _.includes(position_bench, p_id))
			.map(p_id => players_by_id[p_id])
			// map to a list of scores with ids
			.map(player => {
				const id = _.get(player, "id");
				return {
					score:
						getRealOrProjectedScore(id, players_by_id, round, custom_scoring_enabled),
					id
				};
			})
			// Make sure that you're taking the best emergency, 
			// if there's more than one
			.orderBy("score", "desc")
			// Take the highest score
			.first();

		if (!_.isEmpty(emergency_player)) {
			score = _.get(emergency_player, "score", 0);
			// Remove this player from the list of emergencies so they're not used again
			emergency.emergency = _.without(emgs, _.get(emergency_player, "id"));
		}
	}
	else {
		const emergency_id = emergency[position_id];
		if (emergency_id) {
			score =
				getRealOrProjectedScore(emergency_id, players_by_id, round, custom_scoring_enabled);
			// Remove player from emergencies so they can't be doubled up
			emergency[position_id] = 0;
		}
	}

	return score;
};

const compareNumbers = (a, b) => b - a;

export const getTeamProjectedScore = (
	lineup: TLineup,
	players_by_id: TPlayersById,
	league: TLeague,
	round: TRoundWithMatches,
	is_classic: boolean = false
) => {
	if (
		_.some([lineup, players_by_id, league, round], _.isEmpty)
	) {
		return 0;
	}

	const { custom_scoring_enabled } = league;

	const {matches} = round;


	const pure_lineup = _.chain(lineup)
		.omit(["captain", "vice_captain", "bench", "emergency"])
		.value();
	const team_players_ids = _.chain(pure_lineup)
		.values()
		.flatten()
		.filter(_.identity)
		.value();

	const ids_positions = getLineupIdsPositions(pure_lineup);
	const emergency = { ..._.get(lineup, is_classic ? "bench" : "emergency", {}) };

	const leader_id = getProjLeaderId(lineup, players_by_id, round);
	let proj_score = 0;

	if (leader_id) {
		// Add leader projected score, for double points
		proj_score =
			getRealOrProjectedScore(leader_id, players_by_id, round, custom_scoring_enabled) || 0;	
	}

	proj_score += team_players_ids.map(player_id => {
		let score =
			getRealOrProjectedScore(player_id, players_by_id, round, custom_scoring_enabled);
		// If score is null/undefined, player is DNP
		// Add emergency score
		if (_.isNil(score) && !_.isEmpty(emergency)) {
			score = getEmergencyScore(
				emergency,
				ids_positions[player_id],
				round,
				players_by_id,
				is_classic,
				custom_scoring_enabled
			);
		}

		// Make sure we return a number, not null/undefined etc.
		return _.isNumber(score) ? score : 0;
	}).reduce((value, proj_score) => {
		return value + proj_score;
	}, 0);

	let emergency_for_bye = { ..._.get(lineup, is_classic ? "bench" : "emergency", {}) };

	const proj_scores = team_players_ids.map(player_id => {
		const player = _.get(players_by_id, player_id, null);

		if (player === null) {
			return 0;
		}

		const players_match = matches
			.find(m => [m.home_squad_id, m.away_squad_id].includes(player.squad_id));

		const match_status = _.get(players_match, "status", "scheduled");

		let score =
			getRealOrProjectedScore(player_id, players_by_id, round, custom_scoring_enabled);

		// Add emergency score if exsits
		if (isAllTrue([match_status !== "scheduled", score === null, !_.isEmpty(emergency)])) {
			score = getEmergencyScore(
				emergency_for_bye,
				ids_positions[player_id],
				round,
				players_by_id,
				is_classic,
				custom_scoring_enabled
			);
		}
		if(leader_id === player_id){
			score += score;
		}
		score = _.isNil(score) ? 0 : score;

		return score;
	});
	const {is_bye, is_partial_bye} = round;
	if (isAnyTrue([
		is_bye,
		is_partial_bye
	])) {
		let final_scores_arr = proj_scores.sort(compareNumbers).slice(0, 18);

		return final_scores_arr.reduce((value, score) => {
			return value + score;
		}, 0);
	}

	return proj_score;
};

export const getTeamProjectedScoreByMatch = (
	lineup: TLineup,
	players_by_id: TPlayersById,
	coach_players_by_id: TCoachPlayerStatsById,
	round: TRoundWithMatches,
	proj_score_key_prefix?: string = "proj_scores"
) => {
	if(
		_.isEmpty(lineup) ||
		_.isEmpty(players_by_id) ||
		_.isEmpty(coach_players_by_id) ||
		_.isEmpty(round)
	) {
		return 0;
	}

	// Entire team lineup player ids
	const pure_lineup = _.chain(lineup)
		.omit(["captain", "vice_captain", "bench", "emergency"])
		.value();
	const team_players_ids = _.chain(pure_lineup)
		.values()
		.flatten()
		.filter(_.identity)
		.value();

	const id = round.id;
	const proj_score_key = id ? `${proj_score_key_prefix}.${id}` : "";

	const ids_positions = getLineupIdsPositions(pure_lineup);
	const { emergency = {} } = lineup;

	const leader_id = getProjLeaderId(lineup, players_by_id, round);

	const getMatchProjectedScore = match => {
		const { home_squad_id, away_squad_id } = match;

		// players in match
		const team_player_ids_in_match = team_players_ids.filter(player_id => {
			const player = players_by_id[player_id];

			return [home_squad_id, away_squad_id].includes(player.squad_id);
		});

		const leader = team_player_ids_in_match.includes(leader_id) ?
			coach_players_by_id[leader_id] :
			null;
		let proj_score = 0;

		if (leader) {
			// Add leader projected score, for double points
			proj_score = _.result(leader, proj_score_key, 0);
		}

		proj_score += team_player_ids_in_match.map(player_id => {
			let score = _.result(coach_players_by_id[player_id], proj_score_key, 0);

			// Add emergency projected score
			if (!score && !_.isEmpty(emergency)) {
				const emergency_id = emergency[ids_positions[player_id]];
				if (emergency_id && team_player_ids_in_match.includes(emergency_id)) {
					score = _.result(coach_players_by_id[emergency_id], proj_score_key, 0);
				}
			}

			return score;
		}).reduce((value, proj_score) => {
			return value + proj_score;
		}, 0);

		return proj_score;
	};

	return round.matches.reduce((acc, match) => {
		return {
			...acc,
			[match.id]: getMatchProjectedScore(match)
		};
	}, {});
};

type TTeamScoreByMatch = {
	lineup: TLineup,
	players_by_id: TPlayersById,
	round: TRoundWithMatches,
	is_classic?: boolean
}

export const getTeamScoreByMatch =
	({ lineup, players_by_id, round, is_classic }: TTeamScoreByMatch) => {
		if(
			_.isEmpty(lineup) ||
			_.isEmpty(players_by_id) ||
			_.isEmpty(round)
		) {
			return "--";
		}

		const pure_lineup = _.chain(lineup)
			.omit(["captain", "vice_captain", "bench", "emergency"])
			.value();
		const team_players_ids = _.chain(pure_lineup)
			.values()
			.flatten()
			.filter(_.identity)
			.value();

		const id = round.id;
		const score_key = id ? `stats.scores.${id}` : "";

		const ids_positions = getLineupIdsPositions(pure_lineup);
		let emergency = is_classic ? {} : { ...lineup.emergency };

		const leader_id = getLeaderId(lineup, players_by_id, score_key);

		const getMatchScore = match => {
			const { home_squad_id, away_squad_id } = match;

			// players in match
			const team_player_ids_in_match = team_players_ids.filter(player_id => {
				const player = players_by_id[player_id];

				return [home_squad_id, away_squad_id].includes(player.squad_id);
			});

			const leader = team_player_ids_in_match.includes(leader_id) ?
				players_by_id[leader_id] :
				null;
			let score = 0;

			if (leader) {
				// Add leader score, for double points
				score = _.result(leader, score_key, 0);
			}

			score += team_player_ids_in_match.map(player_id => {
				let score = _.result(players_by_id[player_id], score_key, 0);

				// Add emergency score
				if (!score && !_.isEmpty(emergency)) {
					const emergency_id = emergency[ids_positions[player_id]];
					emergency[ids_positions[player_id]] = 0;
					if (emergency_id && team_player_ids_in_match.includes(emergency_id)) {
						score = _.result(players_by_id[emergency_id], score_key, 0);
					}
				}

				return score;
			}).reduce((value, score) => {
				return value + score;
			}, 0);

			return score;
		};

		return round.matches.reduce((acc, match) => {
			return {
				...acc,
				[match.id]: getMatchScore(match)
			};
		}, {});
	};

type TDraftTeamScoreByMatch = {
	players_by_id: TPlayersById,
	scoring_players: Object,
	round: TRoundWithMatches,
	player_scoring_path: string,
};

export const getDraftTeamScoreByMatch =
	({ players_by_id, scoring_players, round, player_scoring_path }: TDraftTeamScoreByMatch) => {
		const scoring_players_arr = scoring_players.players;
  	const scoring_captain  = scoring_players.captain;
		const getMatchScore = match => {
			let score = 0;
			const { home_squad_id, away_squad_id } = match;
			const team_player_ids_in_match = scoring_players_arr.filter(player_id => {

				const player = players_by_id[player_id];

				return [home_squad_id, away_squad_id].includes(player.squad_id);
			});
  		if(team_player_ids_in_match.includes(scoring_captain) && scoring_captain !== 0){
				score += 2 * _.get(players_by_id[scoring_captain], player_scoring_path, 0);
				team_player_ids_in_match.splice(team_player_ids_in_match.indexOf(scoring_captain), 1);
			}
  		const match_scoring_arr= team_player_ids_in_match.map(player_id => {
				return _.get(players_by_id[player_id], player_scoring_path, 0);
			});
			match_scoring_arr.forEach(player_score => {
				score += player_score;
			});

			return score;
		};

		return round.matches.reduce((acc, match) => {
			return {
				...acc,
				[match.id]: getMatchScore(match)
			};
		}, {});
	};
type TTeamScoreFromLadder = {
	team: TTeam,
	is_active_match: boolean,
	round: TRoundWithMatches,
	start_round?: number,
	score?: number | string,
	players_by_id?: TPlayersById,
	is_classic?: boolean,
	default_value?: any,
	scoring_players?: Object,
	custom_scoring?: Object,
	custom_scoring_enabled?: number
}

type LiveDraftTeamScore = {
	round: TRoundWithMatches,
	scoring_players: Object,
	custom_scoring_enabled: number,
	players_by_id: TPlayersById,
	is_active_match: boolean,
	team: TTeam,
}


export const getLiveDraftTeamScore = ({
	round,
	players_by_id,
	scoring_players,
	custom_scoring_enabled,
	is_active_match,
	team
}:LiveDraftTeamScore)  => { 
	const round_id = _.get(round, "id", 0);
	let score = 0;
	if(is_active_match && scoring_players && scoring_players.players){
		if(custom_scoring_enabled){
			scoring_players.players.forEach(player_id => {
				const player = _.get(players_by_id, player_id);
				if(player && 
					player.custom_stats
					&& player.custom_stats.scores
          && player.custom_stats.scores[round_id]){
					if(player_id === scoring_players.captain){
						score += 2 * player.custom_stats.scores[round_id];
					}
					else{
						score += player.custom_stats.scores[round_id];
					}
				}

			});
			return score;
		}
		scoring_players.players.forEach(player_id => {
			const player = _.get(players_by_id, player_id);
			if(player && 
				player.stats
				&& player.stats.scores
          && player.stats.scores[round_id]){
				if(player_id === scoring_players.captain){
					score += 2 * players_by_id[player_id].stats.scores[round_id];
				}
				else{
					score += players_by_id[player_id].stats.scores[round_id];
				}
			}

		});
		return score;
	}
	// if not active return scoreflow so we dont need to iterate
	score = _.get(team, ["scoreflow", round_id], "--");
	return score;
};


export const getTeamScoreFromLadder = ({
	team,
	is_active_match,
	round,
	players_by_id,
	is_classic,
	default_value = "- -"

}: TTeamScoreFromLadder) => {
	const players = players_by_id || {};
	const  round_id = _.get(round, "id");
	// is active match calculate player scores
	if (is_active_match) {

		if (is_classic) {
			return getTeamScore({
				lineup: team.lineup,
				players_by_id: players,
				round,
				team
			});
		}
		const teamScoreflow = _.get(team, `scoreflow.${round_id}`);
		if( isAllTrue([
			!is_classic,
			teamScoreflow
		])){
			return teamScoreflow;
		}
	}
	// if not, get score from scoreflow

	// if (is_classic) {
	// just to see which classic players are scoring
	// 	return getTeamScore({
	// 		lineup: team.lineup,
	// 		players_by_id: players,
	// 		round
	// 	});
	// }

	const score = _.get(team, ["scoreflow", round_id], default_value);
	return score;
};

type TTotalScoreFromLadder = {
	team: TTeam,
	is_active_match: boolean,
	round_id: number,
	start_round?: number,
	score?: number | string,
	players_by_id?: TPlayersById,
	is_classic?: boolean
}

export const getTotalScoreFromLadder = (
	{ team, start_round, round_id, is_active_match, score } : TTotalScoreFromLadder
) => {
	const { scoreflow } = team;

	if (!scoreflow || _.isEmpty(scoreflow)) {
		return "--";
	}

	const end_round = is_active_match ? round_id - 1 : round_id;
	const initial_score = is_active_match && _.isNumber(score) ? score : 0;

	return _.reduce(scoreflow, (sum, points, id: string) => {
		if (end_round >= parseInt(id, 10)) {
			return sum + points;
		}
		return sum;
	}, initial_score);
};

export const leagueStatsSelected =
	(team_property: Object, round_id: number, start_round: number = 1) => {
		const paired_by_key_value_team_stats = _.toPairs(team_property)
			.sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
			.slice(0, round_id).slice(start_round - 1);
		const filtered_team_stats = paired_by_key_value_team_stats
			.filter(scores => parseInt(scores[0], 10) <= round_id);
		const added_team_stats = _.sum(filtered_team_stats.map(point => point[1]));
		return added_team_stats;
	};

export const gamesPlayed =
	(team: TTeam, round_id: number) => {
		const scoreflow = _.get(team, "league_scoreflow", {});
		const league_scoreflow_array = _.toPairs(scoreflow)
			.sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
			.slice(0, round_id);
		const filtered_ls_array = league_scoreflow_array
			.filter(scores => parseInt(scores[0], 10) <= round_id);
		return filtered_ls_array.length;
	};

export const createWinLossRecord =
	(team: TTeam, round_id: number) => {
		const scoreflow = _.toPairs(team.league_scoreflow)
			.sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
			.slice(0, round_id);
		const filtered_scoreflow = scoreflow
			.filter(scores => parseInt(scores[0], 10) <= round_id);
		const recordKeys = {
			"4": "win",
			"2": "draw",
			"0": "loss",
		};

		const record = filtered_scoreflow.reduce((result, [_, value]) => {
			const key = recordKeys[value];

			if(key) result[key]++;

			return result;
		}, { win: 0, draw: 0, loss: 0 });

		return record;

	};

export const createWinLossForm = (team: TTeam, round_id: number) => {
	let record_form = [];
	const scoreflow = _.toPairs(team.league_scoreflow)
		.sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
		.filter(scores => parseInt(scores[0], 10) <= round_id)
		.slice(-5);



	for (let i = 0; i < scoreflow.length; i++) {
		if (scoreflow[i][1] === 4) {
			record_form.push("W");
		}
		else if (scoreflow[i][1] === 2) {
			record_form.push("D");
		}
		else if (scoreflow[i][1] === 0) {
			record_form.push("L");
		}
	}

	const joined_record_form = record_form.join("");

	return joined_record_form;
};

export const countPlayersWithMatchStatus = (
	player_ids: number[],
	players_by_id: TPlayersById,
	round: TRound,
	status: string
) => {
	if (_.isEmpty(round) || _.isEmpty(player_ids) || _.isEmpty(round.matches)) {
		return 0;
	}
	return _(player_ids)
		.map(id => players_by_id[id])
		.filter(_.identity)
		.filter(player => {
			const squad_id = player.squad_id;
			const match = _.find(
				round.matches,
				({ away_squad_id, home_squad_id }) =>
					squad_id === away_squad_id || squad_id === home_squad_id
			);

			return match && match.status === status;
		})
		.value().length;
};

export const getYetToPlay = (player_ids: number[],  players_by_id: TPlayersById, round: TRound) => {
	if (round && round.status === "complete") {
		return 0;
	}

	return countPlayersWithMatchStatus(player_ids, players_by_id, round, "scheduled");
};