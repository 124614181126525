// @flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import type { TSquad, TPlayersArrayWithFixtureById } from "../../../modules/types";
import type { TClassicTeam } from "../../../modules/types/teamClassic";
import type { TPlayer, TPosition } from "../../../modules/types";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import { getPartialByeRound, isEditTradePage } from "../../../helpers";
import {
	ButtonLoadMore, ListViewPlayer,
	PlayerPoolFilters,
	SectionHeader,
	OpenTeamFieldButton,
} from "../../../components";
import EmptyMessage from "../../../components/PlayersTable/emptyMessage";
import type { TRound } from "../../../modules/types/rounds";
import Arrow from "../../../components/Icons/Arrow";

import TradePlayerPoolHeader from "./tradePlayersPool/tradePlayerPoolHeader";



const ONE_DAY_MS = 86400000;
const SHOW_MORE_STEP = 20;

const PlayerWrapper = styled.div`
	.list-view-player:first-child {
		border-top-color: transparent;
	}
`;

type Props = {
	team: TClassicTeam,
	positions: Array<TPosition>,
	players: Array<TPlayer>,
	is_pending_players: boolean,
	squads: Array<TSquad>,
	has_assistant_coach: boolean,
	player_pool_statistic: Object,
	players_by_id: TPlayersArrayWithFixtureById,
	team_ids: Array<number>,
	addPlayer: Function,
	selected_position: string,
	remaining_salary: number,
	is_team_started: boolean,
	actual_round: TRound,
	available_positions: Array<number>,
	is_mobile?: boolean,
	onSwitchPlayerPool?: Function,
	stats_block?: React.Node,
	is_type_field_list: boolean,
	data_loading_complete: boolean,
	openPopup: Function,
	addPlayerInTrade: typeof actions.addPlayerInTrade,
	removePlayerInTrade: typeof actions.removePlayerInTrade,
	players_on_trade: Array<Object>,
	team_trade_ids: Array<number>,
	bye_detector_active: boolean,
	bye_detector_selected: number[],
	bye_detector_controls: React.Node,
	stat_controls: React.Node,
	mobile_stat: Object,
	score_stat: Object,
	player_id: number,
	filter_position_by_trade_player: string | null,
	rounds: Array<Object>,
	active_trade_id: number,
	removeFillerPositionByTradeUser: typeof actions.removeFillerPositionByTradeUser,
};
type TFilters = {
	sort_by: string,
	search: string,
	by_squad: string,
	by_position: string,
	by_status: string,
	by_cost: string,
	by_dpp: boolean,
	by_favourites: boolean,
	by_team_players: boolean
};

type State = {
	order_by_desc: boolean,
	filters: TFilters,
	players_limit: number
};
class TradePlayersPoolComponent extends React.Component<Props, State> {
	static defaultProps = {
		data_loading_complete: false,
		stat_controls: null,
	};
	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"handleFilterSubmit",
			"handleFilterChange",
			"orderByComparator",
			"getPlayerCell",
			"checkIsPlayerChangeAvailable",
			"showMore",
			"addPlayerInTrade",
			"removePlayerInTrade",
			"onToggleOrder",
			"handleSwitchFilterChange"
		]);
	}
	state = {
		by_dpp: false,
		by_favourites: false,
		order_by_desc: true,
		filters: {
			sort_by: this.props.is_mobile ? this.props.mobile_stat.key : "stats.avg_points",
			search: "",
			by_squad: "",
			by_position: "",
			by_status: "",
			by_cost: "",
			by_dpp: false,
			by_favourites: false,
			by_team_players: true
		},
		players_limit: 33
	};
	componentDidMount(){
		const { player_id, addPlayerInTrade, players } = this.props;
		const player_data = players.find(player => player.id === player_id);
		const positions_ids = _.get(player_data, "positions", []);

		if (this.checkIsPlayerChangeAvailable(player_id)) {
			addPlayerInTrade({
				id: parseInt(player_id, 10),
				is_bench: false, in_position: _.first(positions_ids) || 0
			});
		}
	}
	componentDidUpdate(prev_props: Props) {
		const { score_stat: { key: old_score }, mobile_stat: { key: old_mobile } } = prev_props;
		const { mobile_stat: { key: mobile }, score_stat: { key: score } } = this.props;
		const { filters: { sort_by } } = this.state;

		// If the key that we're sorting by has been changed (i.e. last round swapped out for live)
		// update the key we sort by
		const updateSortKey = (old, updated) => {
			if (sort_by === old && updated !== old) {
				this.setState({
					filters: {
						...this.state.filters,
						sort_by: updated
					}
				});
			}
		};

		updateSortKey(old_score, score);
		updateSortKey(old_mobile, mobile);
	}
	get filter_form() {
		const { bye_detector_controls, stat_controls } = this.props;
		const { filters: { by_position } } = this.state;
		return (
			<PlayerPoolFilters
				by_position={by_position}
				onSubmit={this.handleFilterSubmit}
				onChange={this.handleFilterChange}
				handleSwitchFilterChange={this.handleSwitchFilterChange}
				from_trades
				bye_detector_controls={bye_detector_controls}
				controls={stat_controls}
			/>
		);
	}
	get pool_players() {
		const
			{ data_loading_complete } = this.props,
			{ players_limit } = this.state;

		if (!data_loading_complete) {
			return (
				<div>
					Loading, please wait...
				</div>
			);
		}
		let show_players = _.take(this.players, players_limit);
		const load_more = this.players.length >= players_limit;

		return (
			<PlayerWrapper>
				{show_players.length ? (
					<React.Fragment>
						{show_players.map(this.getPlayerCell)}
						{load_more && (
							<ButtonLoadMore onClick={this.showMore}>
								Load More
							</ButtonLoadMore>
						)}
					</React.Fragment>
				) : (
					<EmptyMessage>
						Sorry, there are no players who match this search query.
						Please adjust your filters above and try again.
					</EmptyMessage>
				)}
			</PlayerWrapper>
		);
	}

	getPlayerCell(player) {
		const { id: player_id } = player;
		const {
			team,
			has_assistant_coach,
			is_mobile,
			openPopup,
			players_on_trade,
			is_team_started,
			actual_round,
			rounds,
			bye_detector_active,
			bye_detector_selected,
			mobile_stat,
			score_stat,
			players,
		} = this.props;

		if (!player) {
			return null;
		}

		const is_locked = !this.checkIsPlayerChangeAvailable(player_id);
		const in_players = _.groupBy(players_on_trade, "in");
		const is_on_trade = in_players[player_id];
		const not_traded_players = _.map(this.props.players_on_trade, trade => trade.in || 0);
		const empty_cells = not_traded_players.filter(value => value === 0);

		const players_in_trade = not_traded_players.filter(value => value !== 0).length < 2;
		const is_empty_cell = empty_cells.length && team.week_trades_left !== 0 && players_in_trade;
		const player_data = players.find(player => player.id === player_id);
		const positions_ids = _.get(player_data, "positions", []);

		const player_bye = _.get(player, "stats.bye_round_id");
		const playerSquad = _.get(player, 'squad_id');
		const player_partial_bye = getPartialByeRound(playerSquad, rounds);

		return (
			<ListViewPlayer
				key={player_id}
				player={player}
				is_locked={is_locked}
				is_captain={false}
				is_team_started={is_team_started}
				is_vice={false}
				is_substitute={false}
				out_substitute={false}
				in_substitute={false}
				substitutePlayer={() => null}
				cancelSubstitute={() => null}
				is_utility={false}
				has_assistant_coach={has_assistant_coach}
				openPlayerPopUp={openPopup}
				is_mobile={is_mobile}
				is_trade_view
				removePlayer={this.addPlayerInTrade}
				removePlayerInTrade={this.removePlayerInTrade}
				is_on_trade={is_on_trade}
				player_partial_bye={player_partial_bye}
				is_empty_cell={is_empty_cell}
				selected_round={actual_round}
				position_id={_.first(positions_ids)}
				bye_detector_active={bye_detector_active}
				bye_detector_selected={bye_detector_selected}
				player_bye={player_bye}
				active_trade_id={this.props.active_trade_id}
				no_padding
				mobile_stat={_.get(mobile_stat, "key")}
				player_stat_field={_.get(score_stat, "key")}
			/>
		);
	}
	get players() {
		const { players } = this.props;
		return players.filter(this.players_filter).sort(this.orderByComparator);
	}
	get players_filter() {
		const { filters } = this.state;
		const { team_ids, team_trade_ids } = this.props;

		const {
			by_status,
			by_squad,
			by_favourites,
			by_position,
			by_dpp,
			search,
			by_cost
		} = filters;

		const maybeGetFilter = (value, filter) => value ? filter : _.identity;

		return _.overEvery([
			maybeGetFilter(by_status, _.matches({ status: by_status })),
			maybeGetFilter(by_squad, _.matches({ squad_id: parseInt(by_squad, 10) })),
			maybeGetFilter(by_favourites, _.property("is_favourite")),
			maybeGetFilter(by_position, _.flow([
				_.property("positions"),
				_.partial(_.includes, _, parseInt(by_position, 10)),
			])),
			maybeGetFilter(by_dpp, ({ positions }) => _.eq(_.size(positions), 2)),
			maybeGetFilter(search, (({ first_name, last_name }) => (
				`${first_name} ${last_name}`.toLowerCase().includes(search.toLowerCase())
			))),
			maybeGetFilter(by_cost, ({ cost, locked }) => {
				if (!by_cost || Number(by_cost) === -1) {
					return true;
				}

				const
					[lowest, highest, by_locked] = by_cost.split("-").map(Number),
					player_cost = cost / 1000,
					is_cost_in_range = lowest <= player_cost && player_cost <= highest;

				if(!_.isUndefined(by_locked)){
					return is_cost_in_range && !locked;
				}

				return is_cost_in_range;
			}),
			({ id }) => !team_ids.includes(id) && !team_trade_ids.includes(id)
		]);
	}
	showMore() {
		this.setState({ players_limit: this.state.players_limit + SHOW_MORE_STEP });
	}
	orderByComparator(player_one: Object, player_two: Object) {
		const { actual_round } = this.props;
		const { filters: { sort_by }, order_by_desc } = this.state;

		const getOrder = (inverted_order, sort_by, order_by_desc) => {
			if (inverted_order.includes(sort_by)) {
				return order_by_desc ? -1 : 1;
			}
			return order_by_desc ? 1 : -1;
		};

		const inverted_order = ["stats.break_even", "stats.cost_divided_by_points"];
		const order = getOrder(inverted_order, sort_by, order_by_desc);
		const minimal = Number.MAX_SAFE_INTEGER * order;

		const getPlayerValue = (player: TPlayer) => {
			let key = sort_by;
			if (sort_by === "stats.round_score") {
				key = `stats.scores.${_.get(actual_round, "id")}`;
			}
			if (sort_by === "stats.points_last_round") {
				key = `stats.scores.${_.get(actual_round, "id", 1) - 1}`;
			}
			// Return a string by default, so that it gets bumped to bottom
			return _.get(player, key, "");
		};
		const getResult = (condition, min, player) => {
			const value = getPlayerValue(player);
			return condition(value) ? min : value;
		};
		const isStringOrZero = val => _.isString(val) || _.eq(val, 0);
		const isStringValue = val => _.isString(val);

		const checkToUse = this.state.filters.sort_by === "stats.break_even"
			? isStringValue
	 		: isStringOrZero;
		
		const getResultOrMin = _.partial(getResult, checkToUse, minimal * -1);

		const a_result = getResultOrMin(player_one);
		const b_result = getResultOrMin(player_two);

		const is_bigger = a_result < b_result;
		const is_less = a_result > b_result;

		if (is_bigger) {
			return order;
		}

		if (is_less) {
			return -1 * order;
		}

		return 0;
	}
	formElementToKeyValueObject(form: Object) {
		return Array.from(form.elements).reduce(this.accumulateFormInputsValueByName, {});
	}
	accumulateFormInputsValueByName(accumulator: Object, formElement: Object) {
		const { type, name, checked, value } = formElement;

		if (_.eq(type, "submit")) {
			return accumulator;
		}

		if (!name) {
			throw new Error("All form inputs should be have \"name\" attribute!");
		}

		accumulator[name] = _.eq(type, "checkbox") ? checked : value;

		return accumulator;
	}
	checkIsPlayerChangeAvailable(id) {
		const { actual_round, is_team_started, players } = this.props;
		const player = _.find(players, { id });
		const { status, lockout } = actual_round;
		const is_active = status === "active";
		if(!id){
			return false;
		}

		if (!is_team_started) {
			return true;
		}

		if (is_active) {
			if (lockout === "full") {
				return false;
			}
			else {
				return !_.get(player, "locked", false);
			}
		}

		return true;
	}
	//Handlers
	handleFilterSubmit(event: Object) {
		event.preventDefault();
	}

	get is_edit_page() {
		return isEditTradePage();
	}

	addPlayerInTrade({ currentTarget }) {
		const { id, position } = currentTarget.dataset;
		const { addPlayerInTrade } = this.props;

		addPlayerInTrade({
			id: parseInt(id, 10), position: parseInt(position, 10), is_bench: false
		});
	}

	removePlayerInTrade({ currentTarget }) {
		const { id, position} = currentTarget.dataset;
		const { removePlayerInTrade } = this.props;
	
		removePlayerInTrade({
			id: parseInt(id, 10),
			position
		});
	}

	onToggleOrder(sort_by): void {
		const { filters } = this.state;
		const old_sort_by = filters.sort_by;
		let order_by_desc = !this.state.order_by_desc;

		if (old_sort_by !== sort_by) {
			order_by_desc = true;
		}

		this.setState({
			order_by_desc: order_by_desc,
			filters: {
				...filters,
				sort_by
			}

		});
	}

	handleFilterChange(event: Object) {
		const { filters } = this.state;

		const {
			...rest_filters
		} = this.formElementToKeyValueObject(event.currentTarget);
		const newFilters = {
			...filters,
			...rest_filters
		};
		const newCompareFilters = {
			...rest_filters
		};
		const currentApplicableState = {
			search: this.state.filters.search,
			by_squad: this.state.filters.by_squad,
			by_position: this.state.filters.by_position,
			by_status: this.state.filters.by_status,
		};

		const shouldNotUpdate = _.isEqual(
			currentApplicableState, 
			newCompareFilters
		);
		if(shouldNotUpdate){
			return;
		}

		this.setState({
			// $FlowFixMe
			filters: {
				...newFilters
			},
		});
	}

	handleSwitchFilterChange(name, value){
		this.setState({
			filters: {
				...this.state.filters,
				[name]: value
			}
		});
	}

	get heading() {
		const { onSwitchPlayerPool } = this.props;

		return (
			<SectionHeader>
				<span>Players Pool</span>
				<OpenTeamFieldButton onClick={onSwitchPlayerPool}>
					<Arrow direction="left"/>
					<span>Your team</span>
				</OpenTeamFieldButton>
			</SectionHeader>
		);
	}
	checkIsFoundPositionFilterForTradePlayer() {
		const { filter_position_by_trade_player, removeFillerPositionByTradeUser } = this.props;
		const { filters } = this.state;
		if(filter_position_by_trade_player) {
			filters.by_position = filter_position_by_trade_player;
			removeFillerPositionByTradeUser();
		}
	}
	render() {
		this.checkIsFoundPositionFilterForTradePlayer();
		const { mobile_stat, score_stat } = this.props;
		const {  order_by_desc, filters: { sort_by } } = this.state;

		return (
			<div>
				{this.heading}
				{this.filter_form}
				<TradePlayerPoolHeader
					onToggleOrder={this.onToggleOrder}
					order_by_desc={order_by_desc}
					sort_by={sort_by}
					mobile_stat={mobile_stat}
					score_stat={score_stat}
				/>
				{this.pool_players}
			</div>
		);
	}
}
const mapStateToProps = (state, props) => {
	const actual_round = selectors.rounds.getActualRound(state);
	const last_round = selectors.rounds.getLastCompleteRound(state) || {};
	const { lifted_at = 0, id: last_round_id } = last_round;
	const is_old_scores = new Date().getTime() - new Date(lifted_at).getTime() < ONE_DAY_MS;

	let round_id = _.isEmpty(actual_round) ? 0 : actual_round.id;
	if (is_old_scores && last_round_id) {
		round_id = last_round_id;
	}
	const players = selectors.players.getExtendedPlayersArray(state);
	const trade_id = Number(_.get(props, "match.params.trade_id", 1));
	return {
		players,
		is_old_scores,
		user_trades: selectors.getUserTrades(state),
		actual_round,
		team: selectors.getMyClassicTeam(state),
		rounds: selectors.rounds.getRounds(state),
		team_ids: selectors.getMyClassicTeamPlayersIds(state),
		team_trade_ids: selectors.getClassicTradePlayerOutIds(state),
		positions: selectors.positions.getPositionsArray(state),
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		last_round: selectors.rounds.getLastCompleteRound(state),
		player_ids: selectors.getMyClassicTeamPlayersIds(state),
		selected_round_id: round_id,
		remaining_salary: selectors.getMyClassicTeamRemainingSalary(state),
		active_trade_id: trade_id,
		data_loading_complete: !_.includes([
			state.players.is_pending,
			state.rounds.is_pending,
			state.squads.is_pending,
			state.venues.is_pending,
			state.teamsClassic.show_my.is_pending,
			(Boolean(selectors.getUser(state).assistant_coach))?
				_.isEmpty(state.players.coach_by_id): false
		], true),
		players_on_trade: selectors.getUserTrades(state),
		is_team_started: selectors.isMyTeamStarted(state),
		filter_position_by_trade_player: state.teamsClassic.trades.filter_position_by_trade_player,
	};
};
const mapDispatchToProps = {
	addPlayerInTrade: actions.addPlayerInTrade,
	removePlayerInTrade: actions.removePlayerInTrade,
	removeFillerPositionByTradeUser: actions.removeFillerPositionByTradeUser,
	updateCompletedTrade: actions.updateCompletedTrade,
	addPlayer: actions.addPlayerToMyClassicTeam,
	removePlayer: actions.removePlayerFromMyClassicTeam
};
export const TradePlayersPool = compose(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(TradePlayersPoolComponent);

export default TradePlayersPool;