// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";
import * as helpers from "../../../helpers";
import { below } from "../../../assets/css/media";
import {
	ButtonLoadMore,
	ClassicTeamSelectPositionModal,
	Tooltip,
	PlayerPoolFilters,
	OpenTeamFieldButton
} from "../../../components";
import EmptyMessage from "../../../components/PlayersTable/emptyMessage";
import type { 
	TClassicTeam, 
	TPlayer, 
	TPosition, 
	TRound, 
	TSquad, 
	TPlayersArrayWithFixtureById 
} from "../../../modules/types";
import colors from "../../../assets/css/colors";
import sticky_headers from "../../../assets/css/sticky";
import Arrow from "../../../components/Icons/Arrow";
import ArrowSolid from "../../../components/Icons/ArrowSolid";
import { PoolPlayerCell } from "./playersPool/poolPlayerCell";

const SHOW_MORE_STEP = 20;

const PoolHeading = styled.div`
	background: #7F8A90;
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	color: #fff;
	box-sizing: border-box;
	padding: 10px;
	border-radius: 2px 2px 0 0;

	${({ is_mobile }) => is_mobile ? css`
		justify-content: space-between;

		& > span {
			font-size: 14px;
		}
	` : ""}
`;

const PoolPlayersHeadingStat = styled.div`
	height: 100%;
	width: ${({ is_player }) => is_player ? "210px" : "36px"};
	position: relative;

	color: ${({ is_coach }) => is_coach ? colors.coach : colors.primary.primary};
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	user-select: none;

	display: flex;
	align-items: center;
	justify-content: ${({ is_player }) => is_player ? "flex-start" : "center"};
	text-align: ${({ is_player }) => is_player ? "left" : "center"};

	flex: 1 1 0px;

	${({ is_action }) => is_action && css`
		flex: 0 0 34px;
	`}

	${({ is_player }) => is_player && css`
			box-sizing: border-box;
			padding: 0 0 0 10px;
			flex: 0 0 70%;
			${below.desktop`
				flex-basis: 65%;
			`};
			${below.tablet`
				flex-basis: 250px;
			`};
			${below.phone`
				flex-basis: 180px;
			`};
	`}

	${({ is_score }) => is_score && "display: none;"}

	> .highlighter {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 57px;
		background: linear-gradient(180deg, #FFFFFF 0%, #F0F3F7 100%);
		z-index: -1;

		opacity: 0;
		transition: opacity 0.2s linear;
	}

	&:hover > .highlighter {
		opacity: 1;
	}

	${({ clickable }) => clickable && css`
		cursor: pointer;
	`}

	${below.desktop`
		${({ is_score }) => is_score && "display: flex;"}
	`}

	svg {
		width: 25px;
		height: 25px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		&.top {
			top:0px;
		}

		&.bottom {
			bottom: 0px;
		}
	}

	> svg.top, > svg.bottom {
		opacity: 0;

		&.show {
			opacity: 1;
			fill: ${({ is_coach }) => is_coach ? colors.coach : colors.primary.primary};
		}
	}

	:hover {
		> svg.top, > svg.bottom {
			opacity: 1;
			fill: ${colors.secondary.paleGrey};
			&.show {
				fill: ${({ is_coach }) => is_coach ? colors.coach : colors.primary.primary};
			}
		}
	}
`;

const PoolPlayersHeading = styled.div`
	display: flex;
	align-items: center;
	box-sizing: border-box;
	height: 60px;
	border-bottom: 3px solid #CAD2D8;

	${sticky_headers}

	${below.desktop`
		margin: 0 -20px;
		padding: 0 20px;
		background-color: white;
	`}

	${below.tablet`
		top: 105px;
		margin: 0 -5px 0 -10px;
		padding: 0;
	`}
`;

const PoolPlayers = styled.div`
	height: ${({ is_type_field_list: list, is_mobile }) => list && !is_mobile ? "1535px" : "815px"};
	${({ is_mobile }) => !is_mobile && "width: 300px"};
	overflow: auto;
	-ms-overflow-style: none;

	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
	${below.desktop`
		height: auto;
	`}

	${below.tablet`
		margin: 0 -5px 0 -10px;
	`}
`;

const Message = styled.div`
	padding: 10px;
`;

const PlayerPoolContainer = styled.div`
	/* Add some padding to top of teamgamebar */
	> div:nth-child(2) {
		padding: 20px 20px 0;
		${below.tablet`
			padding: 10px 10px 0;
		`}
	}
`;

type Props = {
	positions: Array<TPosition>,
	players: Array<TPlayer>,
	is_pending_players: boolean,
	squads: Array<TSquad>,
	has_assistant_coach: boolean,
	player_pool_statistic: Object,
	team: TClassicTeam,
	players_by_id: TPlayersArrayWithFixtureById,
	team_ids: Array<number>,
	addPlayer: Function,
	selected_position: string,
	rounds: TRound[],
	is_partial_or_standard: string,
	remaining_salary: number,
	is_team_started: number,
	actual_round: TRound,
	available_positions: Array<number>,
	is_mobile?: boolean,
	onSwitchPlayerPool?: Function,
	stats_block?: React.Node,
	is_type_field_list: boolean,
	bye_detector_selected: number[],
	bye_detector_active: boolean,
	controls: React.Node,
	mobile_stat: string,
	score_stat: Object,
	selected_utility_position: boolean
};

type TFilters = {
	sort_by: string,
	search: string,
	by_squad: string,
	by_position: string,
	by_status: string,
	by_cost: string,
	by_dpp: boolean,
	by_favourites: boolean,
	by_team_players: boolean,
};

type State = {
	order_by_desc: boolean,
	filters: TFilters,
	modal_positions: Array<number>,
	modal_id: number,
	truncate_players: boolean,
	first_bye_sort: boolean,
	last_non_bye_stat: string,
};

export class PlayersPoolComponent extends React.Component<Props, State> {
	static defaultProps = {
		onSwitchPlayerPool: () => {},
		stats_block: null,
		controls: null,
	};

	constructor(props: Props, state: State) {
		super(props, state);

		_.bindAll(this, [
			"getPlayerCell",
			"handleFilterSubmit",
			"handleFilterChange",
			"orderByComparator",
			"addPlayer",
			"closeModal",
			"showPositionsModal",
			"showMore",
			"onToggleOrder",
			"handleSwitchFilterChange"
		]);
	}

	state = {
		by_dpp: false,
		by_favourites: false,
		order_by_desc: true,
		filters: {
			sort_by: "cost",
			search: "",
			by_squad: "",
			by_position: "",
			by_status: "",
			by_cost: "",
			by_dpp: false,
			by_favourites: false,
			by_team_players: true
		},
		modal_positions: [],
		modal_id: 0,
		truncate_players: true,
		first_bye_sort: false,
		last_non_bye_stat: this.props.mobile_stat || "stats.total_points",
	};

	componentDidMount() {
		const
			{ filters } = this.state,
			{ selected_position } = this.props;

		this.setState({
			filters: {
				...filters,
				by_position: selected_position || filters.by_position
			}
		});
	}

	componentDidUpdate(prev_props: Props, prev_state: State) {
		const { 
			selected_position, 
			bye_detector_active, 
			mobile_stat, 
			is_partial_or_standard 
		} = this.props;
		const { filters } = this.state;

		if (prev_props.selected_position !== selected_position) {
			this.setState({
				filters: {
					...filters,
					by_position: selected_position
				}
			});
		}
		if ((!prev_props.bye_detector_active && bye_detector_active) 
			|| prev_props.is_partial_or_standard !== is_partial_or_standard) {
			this.setState({
				filters: {
					...filters,
					sort_by: is_partial_or_standard === "partial" 
						? "partial_bye_round" : "stats.bye_round_id",

				},
				first_bye_sort: true,
			});
		}
		if (!_.isEqual(prev_props.mobile_stat, mobile_stat)) {
			this.setState({
				order_by_desc: mobile_stat !== "stats.bye_round_id",
				filters: {
					...filters,
					sort_by: mobile_stat,
				}
			});
		}
	}

	get pool_heading() {
		const { is_mobile, onSwitchPlayerPool } = this.props;

		if(is_mobile) {
			return (
				<PoolHeading is_mobile={true}>
					<span>Player pool</span>
					<OpenTeamFieldButton onClick={onSwitchPlayerPool}>
						<Arrow direction="left"/>
						<span>Your team</span>
					</OpenTeamFieldButton>
				</PoolHeading>
			);
		}

		const description = "Use the below filters to find the best players for your team";
		return (
			<PoolHeading>
				Select your team from the player pool
				<Tooltip show_on_left description={description} background="transparent" />
			</PoolHeading>
		);
	}

	handleSwitchFilterChange(name:string, value:string){
		this.setState({
			filters: {
				...this.state.filters,
				[name]: value
			}
		});
	}

	get filter_form() {
		const { controls } = this.props;
		const { filters: { by_position, sort_by } } = this.state;
		return (
			<PlayerPoolFilters
				by_position={by_position}
				sort_by={sort_by}
				onSubmit={this.handleFilterSubmit}
				onChange={this.handleFilterChange}
				controls={controls}
				handleSwitchFilterChange={this.handleSwitchFilterChange}
			/>
		);
	}

	get pool_players_heading() {
		const { player_pool_statistic, is_mobile, score_stat } = this.props;
		const { filters: { sort_by }, order_by_desc, first_bye_sort } = this.state;
		const { is_coach = false, short_name } = player_pool_statistic[sort_by] || {};

		// Only show the arrow if we're sorting by the given stat
		// i.e. if it's not the bye stat, or it's the bye stat and
		// we're actually sorting by it
		const show_arrow = sort_by !== "stats.bye_round_id" || !first_bye_sort;

		return (
			<PoolPlayersHeading is_mobile={is_mobile}>
				<PoolPlayersHeadingStat is_player>
					Player
				</PoolPlayersHeadingStat>
				<PoolPlayersHeadingStat is_coach={is_coach} is_score>
					{_.get(score_stat, "short_name")}
				</PoolPlayersHeadingStat>
				<PoolPlayersHeadingStat clickable onClick={this.onToggleOrder} is_coach={is_coach}>
					<ArrowSolid
						className={`top${show_arrow && !order_by_desc ? " show" : ""}`}
						direction="up"
					/>
					{short_name}
					<ArrowSolid
						className={`bottom${show_arrow && order_by_desc ? " show" : ""}`}
						direction="down"
					/>
					<div className="highlighter" />
				</PoolPlayersHeadingStat>
				<PoolPlayersHeadingStat is_action />
			</PoolPlayersHeading>
		);
	}

	showMore() {
		this.setState({ truncate_players: false });
	}

	get pool_players() {
		const
			{ is_pending_players } = this.props,
			{ truncate_players } = this.state;

		if (is_pending_players) {
			return (
				<Message>
					Loading, please wait...
				</Message>
			);
		}
		const { is_mobile, is_type_field_list } = this.props;
		let show_players = this.players;
		const original_size = _.size(show_players);

		if (truncate_players) {
			show_players = _.take(show_players, SHOW_MORE_STEP);
		}
		const is_load_more = truncate_players && original_size > SHOW_MORE_STEP;
		return (
			<PoolPlayers is_mobile={is_mobile} is_type_field_list={is_type_field_list}>
				{show_players.length ? (
					<React.Fragment>
						{show_players.map(this.getPlayerCell)}
						{is_load_more ? (
							<ButtonLoadMore onClick={this.showMore}>
								Load More
							</ButtonLoadMore>
						) : null}
					</React.Fragment>
				) : (
					<EmptyMessage>
						Sorry, there are no players who match this search query.
						Please adjust your filters above and try again.
					</EmptyMessage>
				)}
			</PoolPlayers>
		);
	}

	get players() {
		const { players } = this.props;

		// $FlowFixMe
		return players.filter(this.players_filter).sort(this.orderByComparator);
	}

	getPlayerCell(player: TPlayer) {
		const {
			has_assistant_coach,
			player_pool_statistic,
			is_mobile,
			available_positions,
			bye_detector_active,
			bye_detector_selected,
			actual_round,
			team,
			is_team_started,
			rounds
		} = this.props;
		let { score_stat } = this.props;

		const { filters: { sort_by } } = this.state;
		const { is_coach = false } = player_pool_statistic[sort_by] || {};

		
		const is_team_full_complete_started = 
			Boolean(team.complete) 
				&& actual_round.status !== "scheduled" 
				&& team.start_round <= actual_round.id;
		// $FlowFixMe
		const is_locked = helpers.classicIsLocked({ player, ...this.props });
		
		const player_bye = _.get(player, "stats.bye_round_id");

		if (_.get(score_stat, "key") === "stats.round_score") {
			score_stat = {
				...score_stat,
				key: `stats.scores.${_.get(actual_round, "id")}`
			};
		}

		const week_trades_left = _.get(team, "week_trades_left", 0);

		return (
			<PoolPlayerCell
				key={player.id}
				is_mobile={is_mobile}
				player={player}
				sort_by={sort_by}
				is_coach={is_coach}
				has_assistant_coach={has_assistant_coach}
				addPlayer={this.addPlayer}
				is_locked={is_locked}
				round={actual_round}
				no_positions={available_positions.length === 0}
				bye_detector_active={bye_detector_active}
				bye_detector_selected={bye_detector_selected}
				player_bye={player_bye}
				score_stat={score_stat}
				rounds={rounds}
				week_trades_left={week_trades_left}
				is_team_started={is_team_started}
				is_team_full_complete_started={is_team_full_complete_started}
			/>
		);
	}

	//Handlers
	handleFilterSubmit(event: Object) {
		event.preventDefault();
	}

	onToggleOrder(value: boolean | typeof undefined): void {
		const { first_bye_sort, filters: { sort_by } } = this.state;

		// If the current stat is bye and hasn't been sorted yet,
		// make it sort by bye from now on
		if (first_bye_sort && sort_by === "stats.bye_round_id") {
			this.setState({
				first_bye_sort: false
			});

			return;
		}
		this.setState(state => ({
			order_by_desc: _.isBoolean(value) ? value : !state.order_by_desc,
		}));
	}

	handleFilterChange(event: Object) {
		const { filters } = this.state;
		let { first_bye_sort, last_non_bye_stat } = this.state;

		const {
			...rest_filters
		} = this.formElementToKeyValueObject(event.currentTarget);

		// If sort has changed to bye, make sure it doesn't
		// sort by bye yet
		if (
			filters.sort_by !== "stats.bye_round_id"
			&& rest_filters.sort_by === "stats.bye_round_id"
		) {
			first_bye_sort = true;
		}

		// Keep a record of the last sort by stat that wasn't bye round,
		// so that this ordering can be kept when bye detector activated
		if (rest_filters.sort_by && rest_filters.sort_by !== "stats.bye_round_id") {
			last_non_bye_stat = _.get(rest_filters, "sort_by", "");
		}

		this.setState({
			// $FlowFixMe
			filters: {
				...filters,
				...rest_filters,
			},
			first_bye_sort,
			last_non_bye_stat,
		});
	}
	addPlayer({ id, positions }: Object) {
		const { addPlayer, team: { lineup }, selected_utility_position } = this.props;
		const { modal_positions } = this.state;

		const positions_available = helpers.getClassicTeamAvailablePositions({ lineup, positions });

		if (positions.length > 1 && positions_available.length === 0) {
			return this.showPositionsModal({ id, positions_available: positions });
		}

		if (positions_available.length === 2) {
			return this.showPositionsModal({ id, positions_available });
		}
		addPlayer({
			id, position: positions_available[0],
			player_position: positions,
			selected_utility_position
		});
		if (modal_positions.length) {
			this.closeModal();
		}
	}
	showPositionsModal({ id, positions_available }: Object) {
		this.setState({
			modal_positions: positions_available,
			modal_id: id
		});
	}
	closeModal() {
		this.setState({
			modal_positions: [],
			modal_id: 0
		});
	}

	//Helpers
	formElementToKeyValueObject(form: Object) {
		return Array.from(form.elements).reduce(this.accumulateFormInputsValueByName, {});
	}
	accumulateFormInputsValueByName(accumulator: Object, formElement: Object) {
		const { type, name, checked, value } = formElement;

		if (_.eq(type, "submit")) {
			return accumulator;
		}

		if (!name) {
			throw new Error("All form inputs should be have \"name\" attribute!");
		}

		accumulator[name] = _.eq(type, "checkbox") ? checked : value;

		return accumulator;
	}
	orderByComparator(player_one: Object, player_two: Object) {
		let { filters: { sort_by }, order_by_desc, first_bye_sort, last_non_bye_stat } = this.state;

		// If this is the first time sorting by bye,
		// sort by last non-bye stat
		if (sort_by === "stats.bye_round_id" && first_bye_sort) {
			// Don't actually sort by bye round - just show it
			sort_by = last_non_bye_stat;
		}
		const getOrder = (inverted_order, sort_by, order_by_desc) => {
			if (inverted_order.includes(sort_by)) {
				return order_by_desc ? -1 : 1;
			}
			return order_by_desc ? 1 : -1;
		};

		const inverted_order = ["stats.break_even", "stats.cost_divided_by_points"];
		const order = getOrder(inverted_order, sort_by, order_by_desc);
		const minimal = Number.MAX_SAFE_INTEGER * order;

		// Don't use isStringOrZero for breakeven calcs since 0 is valid value
		const isStringOrZero = val => _.isString(val) || _.eq(val, 0);
		const isStringValue = val => _.isString(val);

		const checkToUse = this.state.filters.sort_by === "stats.break_even"
          	? isStringValue
        	: isStringOrZero;

		const getResult = (condition, min, value) => condition(value) ? min : value;
		const getResultOrMin = _.partial(getResult, checkToUse, minimal * -1);

		const a_result = getResultOrMin(_.get(player_one, sort_by));
		const b_result = getResultOrMin(_.get(player_two, sort_by));

		const is_bigger = a_result < b_result;
		const is_less = a_result > b_result;

		if (is_bigger) {
			return order;
		}

		if (is_less) {
			return -1 * order;
		}

		return 0;
	}
	get players_filter() {
		const { filters } = this.state;
		const { team_ids } = this.props;

		const {
			by_status,
			by_squad,
			by_favourites,
			by_position,
			by_dpp,
			search,
			by_cost
		} = filters;

		const maybeGetFilter = (value, filter) => value ? filter : _.identity;

		return _.overEvery([
			maybeGetFilter(by_status, _.matches({ status: by_status })),
			maybeGetFilter(by_squad, _.matches({ squad_id: parseInt(by_squad, 10) })),
			maybeGetFilter(by_favourites, _.property("is_favourite")),
			maybeGetFilter(by_position, _.flow([
				_.property("positions"),
				_.partial(_.includes, _, parseInt(by_position, 10)),
			])),
			maybeGetFilter(by_dpp, ({ positions }) => _.eq(_.size(positions), 2)),
			maybeGetFilter(search, (({ first_name, last_name }) => (
				`${first_name} ${last_name}`.toLowerCase().includes(search.toLowerCase())
			))),
			maybeGetFilter(by_cost, ({ cost, locked }) => {
				if (!by_cost || Number(by_cost) === -1) {
					return true;
				}

				const
					[lowest, highest, by_locked] = by_cost.split("-").map(Number),
					player_cost = cost / 1000,
					is_cost_in_range = lowest <= player_cost && player_cost <= highest;

				if(!_.isUndefined(by_locked)){
					return is_cost_in_range && !locked;
				}

				return is_cost_in_range;
			}),
			({ id }) => !team_ids.includes(id)
		]);
	}

	get pick_player_modal() {
		const { modal_positions, modal_id } = this.state;
		const { players_by_id } = this.props;

		return (
			<ClassicTeamSelectPositionModal
				id={modal_id}
				player={players_by_id[modal_id]}
				positions={modal_positions}
				addPlayer={this.addPlayer}
				closeModal={this.closeModal}
			/>
		);
	}

	render() {
		const { stats_block } = this.props;
		const { modal_id } = this.state;

		return (
			<PlayerPoolContainer>
				{this.pool_heading}
				{ stats_block }
				{this.filter_form}
				{this.pool_players_heading}
				{this.pool_players}
				{modal_id ? this.pick_player_modal : null}
			</PlayerPoolContainer>
		);
	}
}

const mapStateToProps = state => ({
	team_ids: selectors.getMyClassicTeamPlayersIds(state),
	positions: selectors.positions.getPositionsArray(state),
	players: selectors.players.getExtendedPlayersArray(state),
	squads: selectors.squads.getSquads(state),
	rounds: selectors.rounds.getRounds(state),
	player_pool_statistic: _.keyBy(state.teamsClassic.player_pool_statistic, "key"),
	available_positions: selectors.getAvailableTeamPositions(state)
});
const mapDispatchToProps = {
	addPlayer: actions.addPlayerToMyClassicTeam
};
export const PlayersPool = connect(
	mapStateToProps,
	mapDispatchToProps
)(PlayersPoolComponent);
export default PlayersPool;