// @flow
import _, { get, isEmpty } from "lodash";
import * as React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { compose } from "redux";
import {Redirect, withRouter} from "react-router-dom";
import { below } from "../../assets/css/media";
import { isMobile } from "../../utils/isMobile/index";
import * as actions from "../../modules/actions";
import colors from "../../assets/css/colors";
import { FORMATIOS } from "../../modules/sagas/teamsClassic";
import { isAllTrue, isAnyTrue, isEditTradePage } from "../../helpers";
import {
	withClassicPlayerModal,
	// TradeInCellClassic,
	TradeOutCell,
	ButtonAlert,
	ModalContainer,
	ModalInnerTitle,
	ButtonAccent,
	TradeInCell,
	ButtonSecondary,
	TradeOutCellShow,
	TradeInCellShow,
	ConfirmModalContent
} from "../";
import * as selectors from "../../modules/selectors";
import type { 
	TRoundWithMatches, 
	TPlayersArrayWithFixtureById, 
	TPlayer,
	TRound
} from "../../modules/types";
import { 
	ConfirmButtonsWrapper, 
	ModalInnerSubTitle } from "../../pages/classic/advancedTrades/userTeamStyles";
import Transactions from "../Icons/Transactions";
import Caution from "../Icons/Caution";
import { getPositionOfPlayerinLineup } from "./helpers";



const TradeCellsWrapper = styled.section`
	display: flex;
	align-items: center;
	margin-bottom: 1em;
	justify-content: space-between;
	${below.desktop`
		${css`
			justify-content: space-between;
		`}
	`}
`;

const TradeColumn = styled.div`
	width: 440px;
	${below.desktop`
		${css`
			width: 49%;
			.player-table-cell {
				height: 50px;
			}
			.player-details-info {
				flex-direction: column;
			}
		`}
	`}
`;
const EmptyCell = styled.div`
	border-radius: 5px;
	box-shadow: 0 0 10px 0 rgba(127,138,144,0.5);
	color: ${({ inactive }) => inactive ? "#CAD2D8" : "#01285E"};;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	margin: 0 0 10px;
	box-sizing: border-box;
	font-size: 14px;
	background: ${({ inactive }) => inactive ? "#f8f8fa" : "#fff"};
	
	&:last-child {
		margin-bottom: 0;
	}
	${below.desktop`
		${css`
			height: 50px;
		`}
	`}
`;

const DisabledEmptyCell = styled(EmptyCell)`
	color: rgb(127, 138, 144);
	text-align: center;
`;

const ButtonsWrapper = styled.div`
	margin: 0 50px;
	${below.desktop`
		${css`
			margin: 0;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;
			padding-bottom: 20px;
		`}
	`}
	button {
		width: 160px;
		height: 40px;
		display: block;
		margin: 2.5em 0 1em;
		${below.desktop`
			${css`
				margin: 0 auto .5em;
			`}
		`}
	}
`;
const UserTradesWrapper = styled.div`
	border-radius: 2px;
	background-color: #F8F8FA;
	width: 100%;
	box-sizing: border-box;
	padding: 30px ${({ is_compressed_padding }) => is_compressed_padding ? 15 : 50}px;
	margin-bottom: 20px;
	${({ is_compressed_padding }) => is_compressed_padding ? css`
		margin-top: 20px;
		${ButtonsWrapper} {
			margin: 0 15px;
		}
	` : ""}

	/* Don't show the player info value - only show price changes */
	.player-info > .player-details-info > .player-cost > p:first-child {
		display: none;
	}

	/* Full position names are too long - show shortened */
	.player-info > .player-details-info > .player-positions > span {
		display: none;
		&.show-mobile {
			display: inline;
		}
	}
	${({ short_names }) => short_names && css`
		.player-info > .player-details-info > .player-names {
			max-width: 90px;
		}
	`}
	
	${below.desktop`
		${css`
			padding: 10px;
		`}
	`}
`;

const ErrorMessage = styled.p`
	border-radius: 2px;
	background-color: ${colors.warning};
	color: #fff;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-size: 14px;
	strong {
		font-weight: 600;
	}
	${below.desktop`
		${css`
			display: inline-block;
			text-align: center;
			vertical-align: middle;
			box-sizing: border-box;
			padding: 20px 0 0;
		`}
	`}
	
`;
// const SuccessMessage = styled(ErrorMessage)`
// 	background: ${colors.success};
// `;
// const DisabledDescription = styled.p`
// 	width: 160px;
// 	color: ${colors.secondary.accentGrey};
// 	font-size: 12px;
// 	text-align: center;
// `;

const TradeTitle = styled.p`
	font-family: "SourceSansPro";
	color: ${colors.secondary.accentGrey};
	font-size: 13px;
	margin-bottom: .5em;
	text-align: left;
	font-weight: 600;
`;

const convertToPositionKey = (posStr: string) => {
	const obj = {
		'Defenders': 1,
		'Midfielders': 2,
		'Rucks': 3,
		'Forwards': 4
	};
	return get(obj, posStr, 0);
};

const getOverflowPosition = lineup => {
	const isDefOverflow = get(lineup, '1').filter(num => num !== 0).length > 6;
	const isMidOverflow = get(lineup, '2').filter(num => num !== 0).length > 8;
	const isRuckOverflow = get(lineup, '3').filter(num => num !== 0).length > 2;
	const isFwdOverflow = get(lineup, '4').filter(num => num !== 0).length > 6;

	if(isDefOverflow){
		return 'Defenders';
	}
	if(isMidOverflow){
		return 'Midfielders';
	}
	if(isRuckOverflow){
		return 'Rucks';
	}
	if(isFwdOverflow){
		return "Forwards";
	}
	return '';
};

const getUnderflowPosition = lineup => {
	const isDefUnder= get(lineup, '1').filter(num => num !== 0).length < 6;
	const isMidUnder = get(lineup, '2').filter(num => num !== 0).length < 8;
	const isRuckUnder = get(lineup, '3').filter(num => num !== 0).length < 2;
	const isFwdUnder = get(lineup, '4').filter(num => num !== 0).length < 6;

	if(isDefUnder){
		return 'Defenders';
	}
	if(isMidUnder){
		return 'Midfielders';
	}
	if(isRuckUnder){
		return 'Rucks';
	}
	if(isFwdUnder){
		return "Forwards";
	}
	return '';
};

const remainingSalaryBelowZero = remaining_salary => remaining_salary < 0;

export const isPlayerStarted = (round: TRoundWithMatches, player: TPlayer) => {
	const {squad_id} = player;
	const roundMatches = round.matches;
	const scheduledStatus = 'scheduled';
	if (!roundMatches) {
		return false;
	}
	const playerMatch = roundMatches.find(match => (
		[match.away_squad_id, match.home_squad_id].includes(squad_id)
		&& match.status !== scheduledStatus
	));

	return playerMatch && playerMatch.length !== 0;
};

const getBenchPosition = (player_id, lineup) => {
	const {bench} = lineup;
	let benchPos = 0;
	Object.keys(bench).forEach(benchKey=> {
		if(benchKey === 'utility'){
			return;
		}
		if(benchKey === 'emergency'){
			return;
		}
		const benchLine = get(bench, benchKey, []);

		const isBenchBool = benchLine.includes(player_id);
		if(isBenchBool){
			benchPos = benchKey;
		}
	});
	return benchPos;

};

const checkIsUtility = position => position === "utility" ? "" : position;


const handleStringPos = position => position === "" ? "" : parseInt(position, 10);
type Props = {
	user_trades: Array<Object>,
	fetchMyClassicTrades: typeof actions.fetchMyClassicTrades,
	players_by_id: TPlayersArrayWithFixtureById,
	remaining_salary: number,
	removePlayerOutTrade: typeof actions.removePlayerOutTrade,
	removePlayerInTrade: typeof actions.removePlayerInTrade,
	resetTrade: typeof actions.resetClassicTrade,
	makeTrade: typeof actions.makeClassicTrade,
	actual_round: TRound,
	selected_round?: TRound,
	is_team_started: boolean,
	trade_ids: Array<number>,
	reverseTrade: typeof actions.reverseClassicTrade,
	trade_error: string | null,
	clearClassicTradeError: typeof actions.clearClassicTradeError,
	trades?: Array<Object>,
	short_names: boolean,
	is_static: boolean,
	initial_lineup: Object,
    updateCompletedTrade: typeof actions.updateCompletedTrade,
    team: Object,
    updateTradeEdit: Function,
    trade_id: number,
    user_trades_pending: boolean,
    removePlayer: typeof actions.removePlayerFromMyClassicTeam,
    editClassicTrade: typeof actions.editClassicTrade,
    setPlayerLastRemoved: Function,
    player_last_removed: number,
    clearSuccess: Function,
    history: Object,
    success: boolean,
	fetchMyTeam: typeof actions.fetchMyClassicTeam,
	initial_trades_on_load: [],
	players_on_trade:Array<Object>,
}
type State = {
	show_confirm_modal: boolean,
	show_reverse_modal: boolean,
	trade_ids: Array<number>,
	is_mobile: boolean,
    edit_trade_id: Array<number>,
	position_last_removed: number | string,
	initial_trades_on_load_ids: [],
	initial_players_on_trade: Array<Object>
}
class EditUserTradesComponent extends React.Component<Props, State> {
	static defaultProps = {
		is_static: false,
	};
	constructor() {
		super();

		_.bindAll(this, [
			"tradeIn",
			"tradeOut",
			"removeInPlayer",
			"removeOutPlayer",
			"resetTrade",
			"makeTrade",
			"reverseTrade",
			"getCompleteTradeButton",
			"isCouldBeReversed",
			"checkIsPlayerChangeAvailable",
			"handleConfirmModal",
			"handleReverseModal",
			"handleErrorModalClose",
			"resize",
			"cancelEditing",
			"handleEditTradeClick",
			"handleSuccessModalClose",
			"setPositionLastRemoved",
			"determineFormationForEmptyUtility"
		]);
	}
	state = {
		show_confirm_modal: false,
		show_reverse_modal: false,
		trade_ids: [],
		is_mobile: false,
		short_names: false,
		edit_trade_id: [],
		position_last_removed: 0,
		initial_trades_on_load_ids: [],
		initial_players_on_trade:[]
	};
	componentDidMount(): void {
		
		
		window.addEventListener("resize", this.resize);
		this.resize();
	}
	componentDidUpdate(prev_props) {
		if(prev_props.user_trades_pending && !this.props.user_trades){
			this.props.fetchMyClassicTrades({from_edit: true});
		}
		if(isAllTrue([
			!isEmpty(prev_props.initial_trades_on_load),
			!isEmpty(this.props.initial_trades_on_load),
			isEmpty(this.state.initial_trades_on_load_ids)
		])){
			this.setState({
				initial_trades_on_load_ids: this.props.initial_trades_on_load
			});
		}
		if(isAllTrue([
			!isEmpty(prev_props.players_on_trade),
			!isEmpty(this.props.players_on_trade),
			isEmpty(this.state.initial_players_on_trade)
		])){
			if(this.props.players_on_trade[0].id) {
				this.setState({
					initial_players_on_trade: this.props.players_on_trade
				});	
			}
		}
        
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
		this.resetTrade(true);
	}
	resize() {
		this.setState({
			is_mobile: isMobile()
		});
	}

	setPositionLastRemoved(position: number | string) {
		this.setState({
			position_last_removed: position
		});
	}
	tradeIn(trade, index) {
		// const { selected_round } = this.props;
		const { is_mobile } = this.state;
		const props = {
			is_compressed: is_mobile
		};
		if (trade.in) {
			const player = this.props.players_by_id[trade.in];
			if(!player){
				return this.emptyCell(4);
			}
			const isPlayerChangeAvailable = this.checkIsPlayerChangeAvailable(player.id);
			return <TradeInCell
				key={index}
				player={player}
				with_cost={!is_mobile}
				removePlayer={this.removeInPlayer}
				isPlayerChangeAvailable={isPlayerChangeAvailable}
				updateTradeEdit={() => this.props.updateTradeEdit(trade.id)}
				is_classic
				{...props}
			/>;
		}
		if (!trade.id) {
			return this.emptyCell(index, true);
		}
		const player = this.props.players_by_id[trade.new_player_id];
		if(!player){
			return this.emptyCell(index, true);
		}
		const isPlayerChangeAvailable = this.checkIsPlayerChangeAvailable(player.id);
		return <TradeInCell
			key={index}
			player={player}
			with_cost={!is_mobile}
			removePlayer={this.removeInPlayer}
			isPlayerChangeAvailable={isPlayerChangeAvailable}
			updateTradeEdit={() => this.props.updateTradeEdit(trade.id)}
			is_classic
			{...props}
		/>;
	}
	tradeOut(trade, index) {
		// const { selected_round } = this.props;
		const { is_mobile } = this.state;
		const props = {
			is_compressed: is_mobile
		};
		if (trade.out) {
			const player = this.props.players_by_id[trade.out];
			if(!player){
				return this.emptyCell(index);
			}
			const isPlayerChangeAvailable = this.checkIsPlayerChangeAvailable(player.id);

			return <TradeOutCell
				key={index}
				player={player}
				with_cost={!is_mobile}
				removePlayer={this.removeOutPlayer}
				updateTradeEdit={() => this.props.updateTradeEdit(trade.id)}
				is_compressed={is_mobile}
				isPlayerChangeAvailable={isPlayerChangeAvailable}
				is_classic={false}
				{...props}
			/>;
		}
		if (!trade.id) {
			return this.emptyCell(index);
		}
		const player = this.props.players_by_id[trade.old_player_id];
		if(!player){
			return this.emptyCell(index);
		}
		const isPlayerChangeAvailable = this.checkIsPlayerChangeAvailable(player.id);

		return <TradeOutCell
			key={index}
			player={player}
			with_cost={!is_mobile}
			removePlayer={this.removeOutPlayer}
			updateTradeEdit={() => this.props.updateTradeEdit(trade.id)}
			is_compressed={is_mobile}
			isPlayerChangeAvailable={isPlayerChangeAvailable}
			is_classic={false}
			{...props}
		/>;
	}

	emptyCell(index, isLeft?) {
		const { is_mobile } = this.state;
		if (index === 2) {
			return (
				<DisabledEmptyCell key={index}>
					{is_mobile ?
						"Please complete at least one trade" :
						"Please complete at least one trade before making your third trade"
					}
				</DisabledEmptyCell>
			);
		}
		
		if (index === 3) {
			return (
				<DisabledEmptyCell key={index}>
					{is_mobile ?
						"Please complete at least two trades" :
						"Please complete at least two trades before making your fourth trade"
					}
				</DisabledEmptyCell>
			);
		}
		if(isLeft){
			return (
				<DisabledEmptyCell key={index}>
					Add a player to your team from the Player Pool
				</DisabledEmptyCell>
			);
		}
		return (
			<EmptyCell key={index}>
				{is_mobile ?
					"Add a player to trade" :
					"Add a player to the trade from your team"
				}
			</EmptyCell>
		);
	}

	get is_edit_page() {
		return isEditTradePage();
	}

	isTradeChanged(id: number){
		const { user_trades } = this.props;
		const { initial_players_on_trade } = this.state;
		const currentTrade = user_trades.find(trade => trade.id === id);
		if(!initial_players_on_trade) {
			return false;
		}
		const initialTrade = initial_players_on_trade.find(trade => trade.id === id);
		if(currentTrade && initialTrade){
			const playerInInitial = get(initialTrade,"in",0);
			const playerInCurrent = get(currentTrade,"in",0);
			const playerOutInitial = get(initialTrade,"out",0);
			const playerOutCurrent = get(currentTrade,"out",0);
			return (playerInCurrent !== playerInInitial) || (playerOutCurrent !== playerOutInitial);
		}
		return false;
	}	

	getCompleteTradeButton(trade) {
		const { 
			is_static, 
			actual_round, 
			players_by_id,
			remaining_salary
		} = this.props;
        
		const playerOne = get(players_by_id, trade.in, {});
		const playerTwo = get(players_by_id, trade.out, {});
        
		const isBothLocked = isPlayerStarted(actual_round, playerOne) 
            && isPlayerStarted(actual_round, playerTwo);

		const isPlayerMissing = !trade.in || !trade.out;
		const isDisabled = 
			isPlayerMissing || 
			isBothLocked || 
			remainingSalaryBelowZero(remaining_salary);
		
		

		const isTradeChanged = this.isTradeChanged(trade.id);
		if (is_static) {
			return <div />;
		}

		return (
			<ButtonAccent
				data-ids={trade.id}
				onClick={this.handleEditTradeClick}
				disabled={isDisabled || !isTradeChanged}
			>
				Confirm trade edit
			</ButtonAccent>
		);
	}



	get error_message() {
		const {team, players_on_trade} = this.props;
		const trades_in_num = this.getNumTrades("in");
		const trades_out_num = this.getNumTrades("out");
		const trade_id = Number(_.get(this.props, "match.params.trade_id", 1));
		const trade = players_on_trade.find(tradeObj => get(tradeObj, 'id') === trade_id);
		const { remaining_salary } = this.props;
		
		if (this.is_no_trades) {
			return null;
		}



		const overflowPosition = getOverflowPosition(team.lineup);
		const positionKey = convertToPositionKey(overflowPosition);
		const underflowPosition = getUnderflowPosition(team.lineup);
		const underFlowPositionKey = convertToPositionKey(underflowPosition);
		
		if(isAllTrue([
			overflowPosition !== '',
			underflowPosition !== '',
			underFlowPositionKey !== positionKey
		])){
			return <ErrorMessage>
				Please note that editing this trade may result in a lineup change, 
				by using a DPP player to create a valid team. 
			</ErrorMessage>;
		}

		if(isAllTrue([
			overflowPosition !== '',
			positionKey !== get(trade, 'position') 
		])){
			return <ErrorMessage>
					This trade is invalid,&nbsp;
				<strong>you have too many {overflowPosition}</strong>
			</ErrorMessage>;
		}

		const is_num_trades_equal = this.is_num_trades_equal;
		if (!is_num_trades_equal) {
			const message = trades_in_num > trades_out_num ?
				"you need to add a player from your team to trade out." :
				"you need to add a player from the player pool.";
			return (
				<ErrorMessage>
					This trade is invalid,&nbsp;
					<strong>{message}</strong>
				</ErrorMessage>
			);
		}
		if (remainingSalaryBelowZero(remaining_salary)) {
			return (
				<ErrorMessage>
					This trade is invalid,&nbsp;
					<strong>you have exceeded the salary cap.</strong>
				</ErrorMessage>
			);
		}
		
		


		return null;
	}

	get error_modal() {
		const { trade_error } = this.props;

		return (
			<ModalContainer header_text="Error" onClick={this.handleErrorModalClose}>
				<ModalInnerTitle>
					{trade_error}
				</ModalInnerTitle>
			</ModalContainer>
		);
	}

	get success_modal() {
		const {success} = this.props;

		if(!success){
			return null;
		}
		return <ModalContainer header_text="Success" onClick={this.handleSuccessModalClose}>
			<ModalInnerTitle>
                Trade Updated
			</ModalInnerTitle>
		</ModalContainer>;
        
        
	}

	handleSuccessModalClose() {
		this.props.clearSuccess();
		this.props.history.push('/classic/advanced-trades');
	}
	handleErrorModalClose() {
		const { clearClassicTradeError } = this.props;
		clearClassicTradeError();
		this.props.fetchMyClassicTrades({from_edit: true});
		this.props.fetchMyTeam();
	}

	getNumTrades(side) {
		const { user_trades } = this.props;

		return _.chain(user_trades)
			.map(trade => trade[side])
			.filter(_.identity)
			.size()
			.value();
	}
	isCouldBeReversed(trade) {
		const { swap_ids } = trade;
		const tradeInId = trade.in;
		const tradeOutId = trade.out;
		const ids = [tradeInId, tradeOutId].concat(swap_ids);
		const players_availability = ids.map(this.checkIsPlayerChangeAvailable);
		return !players_availability.includes(false);
	}
	get is_num_trades_equal() {
		return this.getNumTrades("in") === this.getNumTrades("out");
	}
	get is_no_trades() {
		return !this.getNumTrades("in") && !this.getNumTrades("out");
	}

	get trades_by_positions_valid() {
		const { players_by_id, initial_lineup } = this.props;
		const utility_id = _.get(initial_lineup, "bench.utility.id", 0);

		return this.filled_trades.filter(trade => {
			const { in: in_id, position, swap_position, out, swap_ids } = trade;
			const { positions } = players_by_id[in_id];

			return positions.includes(swap_position || position) ||
				(utility_id === out && !swap_ids.length) || _.last(swap_ids) === utility_id;
		});
	}

	get is_positions_valid() {
		return this.trades_by_positions_valid.length === this.filled_trades.length;
	}

	get filled_trades() {
		const { user_trades } = this.props;
		return _.chain(user_trades)
			.filter(trade => trade.in && trade.out)
			.value();
	}

	get is_trade_invalid() {
		const { remaining_salary, team } = this.props;

		const is_num_trades_not_equal = !this.is_num_trades_equal;
		const is_not_in_salary = remaining_salary < 0;
		const isOverflow = getOverflowPosition(team.lineup) !== '';
		return [
			is_num_trades_not_equal, 
			is_not_in_salary, 
			!this.is_positions_valid, 
			isOverflow].includes(true);
	}

	checkIsPlayerChangeAvailable(player_id) {
		const { actual_round, is_team_started, players_by_id } = this.props;
		const player = players_by_id[player_id];
		const { status, lockout } = actual_round;
		const is_active = status === "active";

		if (!is_team_started) {
			return true;
		}

		if (is_active) {
			if (lockout === "full") {
				return false;
			}
			else {
				return !_.get(player, "locked", 1);
			}
		}

		return true;
	}

	removeOutPlayer(currentTarget) {
		const {id} = currentTarget;

		const { user_trades, trade_id, players_by_id, team } = this.props;

		const player = get(players_by_id, id, {}); 
		const possiblePositions = get(player, 'positions', []);
		let playerPos = get(player, 'positions[0]', 0);



		const existingTrade = user_trades.find(trade => trade.id === trade_id);
        
		const { 
			position,
			position_index, 
			is_bench,
			swap_ids
		} = existingTrade || {position: 0, position_index: 0, is_bench: false, swap_ids: []};
		if(position && possiblePositions.includes(position)){
			playerPos = position;
		}
		if(swap_ids.length > 0){
			const playerIdSwapped = swap_ids[0];
			const currentPosition = getPositionOfPlayerinLineup(playerIdSwapped, team.lineup);
			const playerSwapped = get(players_by_id, playerIdSwapped);
			const playerPositions = get(playerSwapped, 'positions');
			playerPos = playerPositions.find(pos => pos !== currentPosition);
		}
		this.props.removePlayerOutTrade({
			id: parseInt(id, 10),
			position_index,
			is_bench,
			position: playerPos
		});
        
	}

	wasPlayerInInitialLineup(playerID) {
		const {initial_trades_on_load_ids} = this.state;
		const playersIn = initial_trades_on_load_ids.reduce((players, trade) => {
			return [...players, trade.in];
		}, []);

		return playersIn.includes(playerID);
	}

	removeInPlayer({ id }) {
		const { team, players_by_id } = this.props;
		
		let position = getPositionOfPlayerinLineup(id, team.lineup);
		const isPlayerInInitialTeam = this.props.initial_trades_on_load.includes(Number(id));
		let positionHandled = position === 'bench' 
			?  getBenchPosition(id, team.lineup) : position;
		positionHandled = checkIsUtility(positionHandled);

		if(isAllTrue([isPlayerInInitialTeam, position !== 0])){ // still in actual team
			const isBench = isAnyTrue([position === 'bench', positionHandled === ""]); 
			this.props.removePlayer({
				id,
				is_bench: isBench,
				is_captain: false,
				is_vice: false,
				is_emergency: false,
				position: positionHandled,
				no_update: true
			});
			this.props.removePlayerInTrade({
				id: parseInt(id, 10),
				position: positionHandled
			});
			this.props.setPlayerLastRemoved(parseInt(id, 10));
			this.setPositionLastRemoved(handleStringPos(positionHandled));
			return;
		}
		if(isAllTrue([
			!isPlayerInInitialTeam,
			position === 0
		])){

			position = this.state.position_last_removed;
			this.props.removePlayerInTrade({
				id: parseInt(id, 10),
				position: position
			});
		
			this.props.setPlayerLastRemoved(parseInt(id, 10));
			this.setPositionLastRemoved(handleStringPos(position));
			return;
		}
		if(positionHandled === 0 ){
			positionHandled = get(players_by_id, `${id}.positions[0]`);
		}
		this.props.removePlayerInTrade({
			id: parseInt(id, 10),
			position: positionHandled
		});
		this.props.setPlayerLastRemoved(parseInt(id, 10));
		this.setPositionLastRemoved(handleStringPos(positionHandled));
	}
	makeTrade() {
		this.props.makeTrade();
		this.handleConfirmModal();
	}
	resetTrade(clear_reset: boolean = false) {
		this.props.resetTrade({
			clear_reset: _.isBoolean(clear_reset) ? clear_reset : false
		});
	}

	reverseTrade() {
		const { trade_ids } = this.state;
		this.props.reverseTrade({ trade_ids });
		this.handleReverseModal();
	}

	handleConfirmModal() {
		const { show_confirm_modal } = this.state;
		this.setState({
			show_confirm_modal: !show_confirm_modal
		});
	}
	handleReverseModal(event) {
		const { show_reverse_modal } = this.state;

		const { currentTarget } = event || {};
		if (!currentTarget) {
			return this.setState({
				show_reverse_modal: !show_reverse_modal,
				trade_ids: []
			});
		}
		const { ids } = currentTarget.dataset;
		const trade_ids = ids ? JSON.parse(`[${ids}]`) : [];
		this.setState({
			show_reverse_modal: !show_reverse_modal,
			trade_ids
		});
	}

	determineFormationForEmptyUtility(trade, team, players_by_id) {
		const playerInTrade = get(players_by_id, trade.in);
		const playerOutTrade = get(players_by_id, trade.out);

		const playerInTradePositions = get(playerInTrade, 'positions');
		const playerOutTradePositions = get(playerOutTrade, 'positions');

		const matchingArr = playerInTradePositions.filter(pos =>
			playerOutTradePositions.includes(pos));

		const overflowPositionName = getOverflowPosition(team.lineup);
		const overflowPosition = convertToPositionKey(overflowPositionName);
		const firstMatchPos = matchingArr.length ? matchingArr[0] : overflowPosition;

		let formation = FORMATIOS[firstMatchPos];

		if (!formation) {
			const playerInPositions = get(playerInTrade, 'positions', []);
			const primaryPositionIn = playerInPositions.length ? playerInPositions[0] : null;

			if (primaryPositionIn && FORMATIOS[primaryPositionIn]) {
				formation = FORMATIOS[primaryPositionIn];
			}
		}
		return formation;
	}

	
	handleEditTradeClick(event) {
		const { user_trades, team, players_by_id } = this.props;
		const trade = user_trades.find(trade => trade.id === this.props.trade_id);
		const lineup = team.lineup;
		const utility = get(lineup, 'bench.utility');
		if (!trade) {
			return;
		}
		let formation = "6-8-2-6/2-2-2-2";
		if (utility.id !== 0) {
			formation = FORMATIOS[utility.position];
		};
		if (utility.id !== 0 && isEmpty(this.state.position_last_removed)) {

			const determinedFormation =
				this.determineFormationForEmptyUtility(trade, team, players_by_id);

			if (determinedFormation) {
				formation = determinedFormation;
			}
		};

		const swapIds = trade.swap_ids_edit ? trade.swap_ids_edit.filter(id => Number(id)) : [];
		const payload = {
			trade_id: trade.id,
			old_player_id: trade.out,
			swap_player_ids: swapIds,
			new_player_id: trade.in,
			double_id: trade.double_id,
			formation: formation,
			swap_formations: trade.swap_formations
		};
		this.props.editClassicTrade(payload);
	}


	cancelEditing() {
		this.setState({
			edit_trade_id: []
		});
	}

	get single_trades() {
		const { user_trades, trades, is_static } = this.props;
		const first_trade_id = _.get(trades, '[0].id');
		const trade_id = Number(_.get(this.props, "match.params.trade_id", first_trade_id));
		const { is_mobile } = this.state;
		const trades_to_filter = trades !== undefined ? trades : user_trades;

		const single_trades =  _.filter(
			// Use the trades provided, if they're given
			trades_to_filter,
			trade => trade.id === trade_id
		);

		const trade_tense = !is_static && "have";

		if (!user_trades.length) {
			return <Redirect to={"/classic/advanced-trades"} />;
		}

		return single_trades.map(trade => {
			const trade_in = <TradeColumn>
				<TradeTitle>
					You {trade_tense} traded in:
				</TradeTitle>
				{this.tradeIn(trade)}
			</TradeColumn>;

			const trade_out = <TradeColumn>
				<TradeTitle>
					You {trade_tense} traded out:
				</TradeTitle>
				{this.tradeOut(trade)}
			</TradeColumn>;

			const buttons = <ButtonsWrapper>
				{this.getCompleteTradeButton(trade)}
			</ButtonsWrapper>;

			return is_mobile
				? <React.Fragment key={trade.id}>
					<TradeCellsWrapper>
						{ trade_in }
						{ trade_out }
					</TradeCellsWrapper>
					{ buttons }
				</React.Fragment>
				: <TradeCellsWrapper key={trade.id}>
					{ trade_in }
					{ buttons }
					{ trade_out }
				</TradeCellsWrapper>;
		});
	}


	get reverse_modal() {
		const { user_trades, players_by_id } = this.props;
		const { trade_ids, is_mobile } = this.state;
		const trades = user_trades.filter(trade => trade_ids.includes(trade.id));
		return (
			<ModalContainer onClick={this.handleReverseModal} container_background="#F8F8FA">
				<Caution size={3} color={colors.warning} />
				<ModalInnerTitle>
					Are you sure you want to reverse this trade?
				</ModalInnerTitle>
				<ModalInnerSubTitle>
					As this was an advanced trade this action will impact all players involved,
					including those you swapped during the trade.
				</ModalInnerSubTitle>
				<ConfirmModalContent>
					<TradeColumn>
						{trades.map(({ in: id }) => (
							<TradeInCellShow
								key={id}
								player={players_by_id[id]}
								compressed={is_mobile}
								is_classic
							/>
						))}
					</TradeColumn>
					{!is_mobile && <Transactions color={colors.primary.primary} size={3.5} />}
					<TradeColumn>
						{trades.map(({ out: id }) => (
							<TradeOutCellShow
								key={id}
								player={players_by_id[id]}
								compressed={is_mobile}
								is_classic
							/>
						))}
					</TradeColumn>
				</ConfirmModalContent>
				<ConfirmButtonsWrapper>
					<ButtonSecondary inverted onClick={this.handleReverseModal}>
						{is_mobile ? "CANCEL" : "No, keep these players"}
					</ButtonSecondary>
					<ButtonAlert
						onClick={this.reverseTrade}
					>
						{is_mobile ? "CONFIRM" : "Yes, reverse this trade"}
					</ButtonAlert>
				</ConfirmButtonsWrapper>

			</ModalContainer>
		);
	}


	render() {
		const { trade_error, user_trades, players_by_id, short_names } = this.props;
		const {show_reverse_modal} = this.state;

		if (_.isEmpty(players_by_id) || _.isEmpty(user_trades)) {
			return null;
		}

		return (
			<UserTradesWrapper is_compressed_padding={user_trades} short_names={short_names}>
				{this.single_trades}


				{this.error_message}
				{show_reverse_modal && this.reverse_modal}
				{this.success_modal}
				{trade_error && this.error_modal}

			</UserTradesWrapper>
		);
	}
}
const mapStateToProps = state => {
	return {
		user_trades: selectors.getUserTrades(state),
		team: selectors.getMyClassicTeam(state),
		success: state.teamsClassic.trades.success,
		initial_trades_on_load: state.teamsClassic.trades.initial_trades_on_load,
		user_trades_pending: state.teamsClassic.trades.is_pending,
		players_by_id: selectors.players.getExtendedPlayersById(state),
		remaining_salary: selectors.getMyClassicTeamRemainingSalary(state),
		actual_round: selectors.rounds.getActualRound(state),
		is_team_started: selectors.isMyTeamStarted(state),
		trade_error: selectors.getTradesError(state),
		initial_lineup: _.get(
			selectors.setUtilityPosition(state.teamsClassic.trades),
			"lineup",
			[]
		),
		players_on_trade: selectors.getUserTrades(state),
	};
};
const mapDispatchToProps = {
	fetchMyClassicTrades: actions.fetchMyClassicTrades,
	removePlayerOutTrade: actions.removePlayerOutTrade,
	removePlayerInTrade: actions.removePlayerInTrade,
	resetTrade: actions.resetClassicTrade,
	makeTrade: actions.makeClassicTrade,
	reverseTrade: actions.reverseClassicTrade,
	clearClassicTradeError: actions.clearClassicTradeError,
	updateCompletedTrade: actions.updateCompletedTrade,
	addPlayer: actions.addPlayerToMyClassicTeam,
	removePlayer: actions.removePlayerFromMyClassicTeam,
	editClassicTrade: actions.editClassicTrade,
	fetchUserTrades: actions.fetchMyClassicTrades,
	clearSuccess: actions.clearSuccessEditTrade,
	fetchMyTeam: actions.fetchMyClassicTeam
};
export const EditClassicUserTrades = compose(
	withRouter,
	withClassicPlayerModal,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)
	
)(EditUserTradesComponent);

export default EditClassicUserTrades;