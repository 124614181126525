// @flow
import React from "react";
import * as _ from "lodash";
import styled, { css } from "styled-components";
import Lock from "../Icons/Lock";
import colors from "../../assets/css/colors";
import isMobile from "../../utils/isMobile";
import { below } from "../../assets/css/media";
import { isAllTrue, isAnyTrue } from "../../helpers";
import type { TBarStat } from "./playerStats";
import { PlayersWrapper } from "./PlayersWrapper";
import breakpoints from "./breakpoints";

const darkGrey = "#B2B7C3";

const isTopScore = (value: number, values: number[], order_desc?: boolean) => {
	const bestScore = order_desc ? Math.min : Math.max;
	const is_top = value === bestScore(..._.filter(values, v => _.isNumber(v)));
	const is_tie = _.indexOf(values, value) !== _.lastIndexOf(values, value);
	return is_top && !is_tie;
};

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: ${({ width }) => width && `${width}%`};
`;

const getBarWidth = (value: number, min: number, max: number, order_desc?: boolean) => {
	/**
	 * Gets the stats bar width
	 * 
	 * Minimum width (percent_diff of 0%) is 20%
	 * Maximum width (percent_diff of 100) is 80%
	 *   Always              stat width               never
	 *  ---------- ---------- ---------- ---------- ---------- 
	 * |////////// ################################ ///////// |
	 *  ---------- ---------- ---------- ---------- ---------- 
	 */

	// percent_diff is the ratio of width of bar - should be between 0 and 1
	const percent_diff = Math.max(Math.min((value - min) / (max - min), 1), 0);

	const abs_width = (20 + 60 * percent_diff);
	const width = order_desc ? 100 - abs_width : abs_width;

	return `${width}%`;
};

const FcBlock = styled.div`
	cursor: pointer;
	color: ${colors.coach};
	display: flex;
    justify-content: center;
    align-items: center;
	width: 100%;
	svg {
		font-size: 20px;
	}
`;

const StatsBar = styled.div`
	height: 20px;
	background-color: ${colors.form.base};
	border-radius: 15px;
	max-width: 300px;
	margin-right: 10px;
	${({ is_last }) => is_last && "margin-right: 0px"};

	flex: 1 0 0px;
	display: flex;


	> div  {
		height: 20px;
		background-color: ${({ is_top }) => is_top ? colors.primary.accent : darkGrey};

		${({ is_coach }) => is_coach && css`
			background-color: ${({ is_top }) => is_top ? colors.fc.primary : colors.fc.accent};
		`}
		border-radius: 15px;

		width: ${({ bar_width }) => bar_width};
		max-width: 300px;


		display: flex;
		align-items: center;
		padding: 0 5px;
		color: #fff;

		> span {
			width: ${({ num_of_stats }) => num_of_stats < 4 ? "50px" : "40px"};
			font-size: 12px;
			margin-top: 2px;
		}

		${({ no_bar }) => no_bar && css`
			width: auto;
			background-color: unset;
			color: ${({ is_coach }) => is_coach ? colors.coach : colors.secondary.accentGrey};
		`};
	}

	justify-content: flex-start;

	${({ reverse }) => reverse && css`
		justify-content: flex-end;
		> div {
			text-align: right;
			justify-content: flex-end;
		}
	`}
	${below.phone}{
		max-width: 95%;
		width: 95%;
		margin-right: 0px;
	}
`;

const EmptyStatsBar = styled(StatsBar)`
	${({ fixed_size }) =>fixed_size && "flex: 0 1 300px"};
	margin-right: 10px;
	${({ is_last }) => is_last && "margin-right: 0px"};
	
`;

const HeaderRow = styled.div`
	width: 100%;
	display: flex;

	> ${EmptyStatsBar} {
		background-color: transparent;
		margin-left: 10px;
		height: 10px;
	}

	& > div {
		@media (max-width: ${breakpoints.three.max}) and (min-width: ${breakpoints.three.min}) {
			${({ size }) => size === 3 && "display: none;"}
		}
		@media (max-width: ${breakpoints.two.max}) {
			&:last-child {
				display: none;
			}
		}
	}
`;

const StatsRow = styled(PlayersWrapper)`
	width: 100%;
	margin: 0;
	justify-content: flex-start;
	> div {
		margin: 0;
		margin-right: 10px;
	}
	${({ is_coach }) => is_coach && css`
		& > ${StatsBar} {
			background-color: ${colors.form.coachBase};
		}
	`}
`;

const StatsName = styled.h3`
	color: ${({ is_coach }) => is_coach ? colors.coach : colors.primary.primary};
	align-self: flex-start;
  font-family: SourceSansPro;
  font-weight: 600;
	font-size: 12px;
  margin-bottom: 10px;

	flex: 1 0 0px;
	text-align: left;

	${({ two_or_less }) => two_or_less && css`
		align-self: center;
		@media (max-width: ${breakpoints.two.max}) {
			margin-right: 0;
		}
	`};
`;

const StatsBlockWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	margin-bottom: 30px;
	${({ is_mob }) => is_mob && `
		margin-left: 0px;
		width: 95%;
		align-self: center;
	`}
`;

type TValue = {
	display_value: string,
	raw_value: number
}

type Props = {
	values: TValue[],
	stat: TBarStat,
	columns: number,
	has_coach: boolean,
	toggleFantasyCoachModal: Function,
	mobIndexToShow: number,
}

export const StatsBlock = ({
	stat,
	values,
	columns,
	has_coach,
	toggleFantasyCoachModal,
	mobIndexToShow
}: Props) => {
	const { 
		name,
		max,
		min,
		is_coach,
		not_winnable,
		order_desc
	} = stat;
	const num_of_stats = _.size(values);
	const two_or_less = num_of_stats <= 2;
	const raw_values = _.map(values, v => v.raw_value);
	let placeholders = _.range(num_of_stats, columns);
	const isCoachValueButUserNoCoach = isAllTrue([
		!!is_coach,
		!has_coach
	]);
	const showValue = isAnyTrue([
		!is_coach,
		isAllTrue([
			!!is_coach,
			has_coach
		])
	]);
	
	const empty = index  => num_of_stats < 4 && 
		<EmptyStatsBar 
			key={`${stat.id}-${index}-empty-val`} 
			fixed_size bar_width={"20%"}> <div /> </EmptyStatsBar>;
	const last_empty = index =>
		<EmptyStatsBar 
			key={`${stat.id}-${index}-empty-last`} 
			is_last fixed_size bar_width={"20%"}> <div /> </EmptyStatsBar>;
	if(isMobile()){
		const value = values[mobIndexToShow];
		const is_top = showValue ? isTopScore(value.raw_value, raw_values, order_desc) : false;
		const width = showValue ? getBarWidth(value.raw_value, min, max, order_desc) : '20%';
		return(
			<React.Fragment>
				<StatsBlockWrapper is_mob>
					<HeaderRow size={num_of_stats}>
						<StatsName two_or_less={two_or_less} is_coach={is_coach}>{name}</StatsName>
					</HeaderRow>
					{!_.isEmpty(value)  ?
						<StatsBar 
							is_top={is_top}
							no_bar={false} 
							bar_width={width} 
							reverse={false}
							is_coach={is_coach}
							order_desc={order_desc}
							num_of_stats={num_of_stats}
						>
							{
								isCoachValueButUserNoCoach
									? <FcBlock onClick={toggleFantasyCoachModal}><Lock /></FcBlock>
									: <div>
										<span>{value.display_value}</span>
									</div>
							}
						</StatsBar> :
						<EmptyWrapper width={100}>
							<EmptyStatsBar bar_width={"20%"}> <div/> </EmptyStatsBar>
						</EmptyWrapper>
					}
				</StatsBlockWrapper>
			
				
				
			</React.Fragment>
			
		);
	}
	return <StatsBlockWrapper>
		<HeaderRow size={num_of_stats}>
			<StatsName two_or_less={two_or_less} is_coach={is_coach}>{name}</StatsName>
		</HeaderRow>
		<StatsRow is_coach={is_coach}>
			{!_.isEmpty(values) &&
				
					_.map(values, (value: TValue, i: number) => {
						const is_top = showValue
							? isTopScore(value.raw_value, raw_values, order_desc)
							: false;
						const width = showValue
							? getBarWidth(value.raw_value, min, max, order_desc)
							: '20%';
						const not_number = !_.isNumber(value.raw_value);
						const is_last = values.length - 1 === i;
						return <StatsBar 
							is_top={is_top} 
							no_bar={not_winnable || not_number} 
							bar_width={width} 
							key={i} 
							reverse={false}
							is_coach={is_coach}
							order_desc={order_desc}
							num_of_stats={num_of_stats}
							is_last={is_last}
						>
							{
								isCoachValueButUserNoCoach
									? <FcBlock onClick={toggleFantasyCoachModal}><Lock /></FcBlock>
									: <div>
										<span>{value.display_value}</span>
									</div>
							}
						</StatsBar>;
					})
			}
			<EmptyWrapper width={100-(4 - placeholders.length)*25}>
				
				{
					_.map(placeholders, i => i !== (placeholders.length - 1) 
						? empty(i) : last_empty(i))
				}

			</EmptyWrapper>
			

		</StatsRow>
	</StatsBlockWrapper>;
};

export default StatsBlock;